var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"bottom":"","color":"primary","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.performAction('', 'create', '')}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Create New Role")])]),_c('v-row',{staticClass:"d-flex align-center mt-10"},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"12","md":"8","lg":"9","xl":"8"}},[_c('v-card',[_c('v-card-text',[_c('v-subheader',{staticClass:"title font-weight-medium pa-0 ma-0"},[_vm._v("Roles")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.getRoles,"search":_vm.search,"loading":_vm.fetching,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical-circle-outline")])],1)]}}],null,true),model:{value:(item.showActions),callback:function ($$v) {_vm.$set(item, "showActions", $$v)},expression:"item.showActions"}},[_c('v-card',[_c('v-list',[_c('v-subheader',[_vm._v("Actions")]),_c('v-divider'),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.actionIcon),function(acti,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.performAction(item.branchid, acti.text, item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(acti.icon)},on:{"click":function($event){return _vm.performAction(
                                    item.branchid,
                                    acti.text,
                                    item
                                  )}}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(acti.text)}})],1)],1)}),1)],1)],1)],1)]}}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are You Sure? ")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete this Role. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.deletetingRole}},[_vm._v(" Agree ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":"2000","color":"success","outlined":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(" mdi-cancel ")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.getRoleSnackMessage)+" ")]),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }