<template>
    <div>
        <!-- {{getDeoVerificationDetails}} -->
        <v-container>
            <v-row v-if="!showComponent">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mt-4">
            <v-card-title>
              DEO Verification Details
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
              <v-data-table
                :headers="headers"
                :items="getDeoVerificationDetails"
                :search="search"
                :loading="pendingLoader"
                class="elevation-0"
              >
                <template v-slot:item.status="{ item }">
                  <v-chip
                    :color="getColor(item.status)"
                    dark
                  >
                    {{ item.status }}
                  </v-chip>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-hover v-slot="{ hover }">
                      <v-btn
                      color="primary"
                      :elevation="hover ? 8 : 0"
                      outlined
                      rounded
                      depressed
                      class="text-capitalize"
                      small
                      @click="showVerificationDetails(item)"
                      >Details</v-btn>
                  </v-hover>
                </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <verification-details :verification="verification" v-else-if="showComponent = 'verification-detail'"></verification-details>
        </v-container>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import verificationDetails from '../../components/DeoComponents/verificationDetails';
import moment from 'moment';

    export default {
        props: ["showComponent"],
        data(){
            return {
                headers: [
                    { text: "Name", value: "refrence" },
                    { text: "Verification Date", value: "date", sortable: true
                    },
                    { text: "Service", value: "service", sortable: false },
                    { text: "Verification Type", value: "type", sortable: false },
                    { text: "Country", value: "thecountry", sortable: false },
                    { text: "Status", value: "status", sortable: false },
                    { text: "Action", value: "action", sortable: false },
                ],
                search: "",
                verification: [],
                pendingLoader: true,
                madate: "2021-03-18T10:16:34",
            }
        },
        components: {
            verificationDetails
        },
        computed: {
            ...mapGetters("deo", ["getSingleDeoDetails", "getDeoVerificationDetails"])
        },
        created()
        {
          console.log("Moment Date:",moment(this.madate).format());
          this.dateImplementation();
          this.deoDetailsFetch({deo_id: this.getSingleDeoDetails.deo_id})
            .then(res => {
              // console.log('response:', res);s
              this.pendingLoader = false;
              this.$store.commit('deo/setDeoVerificationDetails', res.data)
            }).catch(e => {
              console.log('error', e)
            });
        },
        methods: {
            ...mapActions("deo", ["deoDetailsFetch"]),
            dateImplementation(){
              var d = new Date()
              var start = new Date().toJSON().slice(0,10).replace(/-/g,'/');
              var end = "2020/03/15";
              console.log(moment(start).format(), 'start:end', moment(end).format());
            },
            getColor (status) {
                if (status == 'Pending') return 'orange'
                else if(status == 'verified') return 'green'
                else return 'red'
            },
            showVerificationDetails(item){
                // console.log(item)
                this.verification = item;
                this.$router.push({
                    name: 'deoDetails',
                    params: {showComponent: 'verification-detail'}
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>