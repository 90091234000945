<template>
  <div>
    <v-row>
        <v-col cols="6">
            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Business Name: {{invoiceData[0].businessname}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>City: {{invoiceData[0].thecity}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Office Phone No: {{invoiceData[0].theofficephoneno}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Zip Code: {{invoiceData[0].thezip}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>


    
        </v-col>
        <v-col cols="6">
                        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Branch Name: {{invoiceData[0].branchname}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Country: {{invoiceData[0].thecountry}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Province: {{invoiceData[0].theprovince}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Head Office: {{invoiceData[0].isheadoffice}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions,mapGetters,mapMutations} from 'vuex'
export default {
    data(){
        return{
            invoiceData:[],
        }
    },
    created(){
        this.viewUser().then((res)=>{
            console.log("viewUser",res)
            this.invoiceData = res.data.data
        }).catch((err)=>{
            console.log("viewUser",err)
        })
    },
    methods:{
        ...mapActions("user",["viewUser"]),
        
    }
}
</script>

<style>

</style>