<template>
  <div>
    <v-navigation-drawer width="380" clipped left app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="subtitle-1">
            Extracted Details
          </v-list-item-title>
          <v-list-item-subtitle>
            Image & video Details
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-tabs
        fixed-tabs
        v-model="tab"
        active-class="deep-orange darken-2 white--text"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
      >
        <v-tab class="text-capitalize">
          <v-icon left>mdi-camera-outline</v-icon> Images Details
        </v-tab>
        <v-tab class="text-capitalize">
          <v-icon left>mdi-video-outline</v-icon> Video Details
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <v-card-text class="pa-2">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-hover v-slot="{ hover }" open-delay="200">
                    <v-card
                      :elevation="hover ? 4 : 1"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-card-text class="pa-3">
                        <v-subheader
                          class="subtitle-2 font-weight-medium pa-0 mb-2"
                          style="height:18px"
                        >
                          Front Card Image</v-subheader
                        >
                        <inner-image-zoom
                          :src="form.frontImage"
                          :zoomSrc="form.frontImage"
                        />
                        <!-- <image-zoom :regular="form.frontImage"> </image-zoom> -->
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-hover v-slot="{ hover }" open-delay="200">
                    <v-card
                      :elevation="hover ? 4 : 1"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-card-text class="pa-3">
                        <v-subheader
                          class="subtitle-2 font-weight-medium pa-0 mb-2"
                          style="height:18px"
                        >
                          Back Card Image</v-subheader
                        >
                        <inner-image-zoom
                          :src="form.backImage"
                          :zoomSrc="form.backImage"
                        />
                        <!-- <image-zoom :regular="form.backImage"> </image-zoom> -->
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-text class="pa-2">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-hover v-slot="{ hover }">
                    <v-card rounded :elevation="hover ? 6 : 2">
                      <v-subheader
                        class="subtitle-2 font-weight-medium py-4 mb-0"
                        style="height:18px"
                        >ID Card Front Video <v-spacer></v-spacer>
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-card-text>
                        <div class="text-center">
                          <!-- <img
                        :src="`${form.frontCardImage}`"
                        alt=""
                        width="100%"
                        height="260px"
                        class="extracted-img"
                      /> -->
                          <video
                            width="100%"
                            height="200px"
                            class="extracted-img"
                            controls
                            loop
                          >
                            <source
                              :src="`${form.frontCardImage}`"
                              type="video/mp4"
                            />
                            <source
                              :src="`${form.frontCardImage}`"
                              type="video/webm"
                            />
                            <source
                              :src="`${form.frontCardImage}`"
                              type="video/ogg"
                            />
                            Video tag is not supported in this browser.
                          </video>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-hover v-slot="{ hover }">
                    <v-card rounded :elevation="hover ? 6 : 2">
                      <v-subheader
                        class="subtitle-2 font-weight-medium py-4 mb-0"
                        style="height:18px"
                        >ID Card Back Video <v-spacer></v-spacer>
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-card-text>
                        <div class="text-center">
                          <!-- <img
                        :src="`${form.backCardImage}`"
                        width="100%"
                        height="260px"
                        class="extracted-img"
                      /> -->
                          <video
                            width="100%"
                            height="200px"
                            class="extracted-img"
                            controls
                            loop
                          >
                            <source
                              :src="`${form.backCardImage}`"
                              type="video/mp4"
                            />
                            <source
                              :src="`${form.backCardImage}`"
                              type="video/webm"
                            />
                            <source
                              :src="`${form.backCardImage}`"
                              type="video/ogg"
                            />
                            Video tag is not supported in this browser.
                          </video>
                          <!-- <v-btn @click="pauseBackImage" class="" icon>
                        <v-icon
                          dark
                          v-if="button2 == 'Play'"
                          medium
                          color="primary"
                          >mdi-play-circle</v-icon
                        >
                        <v-icon
                          dark
                          v-if="button2 == 'Pause'"
                          medium
                          color="error"
                          >mdi-pause-circle</v-icon
                        >
                      </v-btn> -->
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-hover>

                  <!-- <button id='back' @click="pauseBackImage">Pause</button> -->
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-hover v-slot="{ hover }">
                    <v-card rounded :elevation="hover ? 6 : 2">
                      <v-subheader
                        class="subtitle-2 font-weight-medium py-4 mb-0"
                        style="height:18px"
                        >Selfie/Video<v-spacer></v-spacer>
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-card-text>
                        <div class="text-center">
                          <video
                            width="100%"
                            height="200px"
                            class="extracted-img"
                            controls
                            loop
                          >
                            <source
                              :src="`${form.faceImage}`"
                              type="video/mp4"
                            />
                            <source
                              :src="`${form.faceImage}`"
                              type="video/webm"
                            />
                            <source
                              :src="`${form.faceImage}`"
                              type="video/ogg"
                            />
                            Video tag is not supported in this browser.
                          </video>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-hover>

                  <!-- <button id='face' @click="pauseFaceImage">Pause</button> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-navigation-drawer>
    <v-navigation-drawer width="290" clipped right app>
      <template v-slot:prepend>
        <v-subheader class="subtitle-1 font-weight-medium"
          >User Detail</v-subheader
        >
      </template>

      <v-divider></v-divider>
      <v-list flat>
        <v-subheader class="subtitle-2 font-weight-medium"
          >Main Detail
        </v-subheader>
        <v-list-item-group v-model="settings" multiple>
          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.message.checks.name"
                  :input-value="dataReceived.message.checks.name"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-subtitle class="subtitle-2"
                  >Full Name</v-list-item-subtitle
                >
                <v-list-item-title class="font-weight-medium"
                  >{{ dataReceived.message.full_name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.message.checks.dob"
                  :input-value="dataReceived.message.checks.dob"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-subtitle class="subtitle-2"
                  >Date of Birth</v-list-item-subtitle
                >
                <v-list-item-title class="font-weight-medium">{{
                  dataReceived.message.dob
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.message.checks.expiry"
                  :input-value="dataReceived.message.checks.expiry"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-subtitle class="subtitle-2"
                  >Card Expiry Date</v-list-item-subtitle
                >
                <v-list-item-title class="font-weight-medium">{{
                  dataReceived.message.expiry_date
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>

        <v-subheader class="subtitle-2 font-weight-medium"
          >Compared Detail</v-subheader
        >

        <v-list-item-group v-model="settings" multiple>
          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.message.checks.face"
                  :input-value="dataReceived.message.checks.face"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Face Image</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.message.checks.face_compare"
                  :input-value="dataReceived.message.checks.face_compare"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Face Compare</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.message.checks.country_compare"
                  :input-value="dataReceived.message.checks.country_compare"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Country Compare</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.message.checks.document_image"
                  :input-value="dataReceived.message.checks.document_image"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Document Image</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-subheader class="subtitle-2 font-weight-medium"
        >Rejection Remarks</v-subheader
      >
      <div class="pa-4">
        <v-select
          v-model="form.remarks"
          :items="rejectItems"
          filled
          label="Rejection Remarks"
          multiple
        >
          <template v-slot:prepend-item>
            <v-card flat>
              <v-card-text class="pa-2">
                <v-text-field
                  label="Other Reason"
                  v-model="form.remarks"
                  filled
                  hide-details="auto"
                  single-line
                ></v-text-field>
              </v-card-text>
            </v-card>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </div>

      <template v-slot:append>
        <div class="px-5 py-3 grey lighten-4">
          <v-btn
            class="text-capitalize primary"
            color="primary"
            @click="
              message = 'verifyMesssage';
              dialog = true;
            "
            :disabled="
              !isAllowedToVerify ||
                selectedKYBMembers.length > 0 ||
                selectedSanctionMembers.length > 0 ||
                selectedPepMembers.length > 0 ||
                selectedAMLMembers.length > 0
            "
            ><v-icon left>mdi-check-decagram</v-icon> Verify</v-btn
          >
          <v-btn
            class="text-capitalize mr-0 float-right"
            color="error"
            :disabled="
              form.remarks == '' &&
                !selectedKYBMembers.length &&
                  !selectedSanctionMembers.length &&
                  !selectedPepMembers.length &&
                  !selectedAMLMembers.length
            "
            @click="
              message = 'rejectMessage';
              dialog = true;
            "
            ><v-icon left>mdi-close-octagon</v-icon> Reject
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-container fluid>
          <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
           <v-card>
               <v-card-title class="pt-0 pb-0 subtitle-1 font-weight-medium">
              Extracted Details
              <v-spacer></v-spacer>
              <v-subheader class="subtitle-2 font-weight-medium "
                >Face Comparison</v-subheader
              >
              <v-chip
              :color="form.verificationStatus ? 'green': 'red'"
                small
                class="white--text ml-0"
                >{{form.verificationStatus ? "Verified" : "Unverified"}}</v-chip
              >
            </v-card-title>
              <v-divider></v-divider>
            <v-card-text>
                   <v-row>
              <v-col cols="8" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  label="Person Name"
                  dense
                  v-model="form.personName"
                  filled
                  hide-details
                ></v-text-field>
              </v-col>
               <v-col cols="8" sm="12" md="6" lg="6" xl="6">
                 <v-text-field
                  label="Father Name"
                  dense
                  filled
                  hide-details
                  v-model="form.fatherName"
                ></v-text-field>
              </v-col>
               <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                 <v-text-field
                  label="CNIC"
                  dense
                  filled
                  hide-details
                  v-model="form.CNIC"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                 <v-text-field
                  label="License"
                  dense
                  filled
                  hide-details
                  v-model="form.license"
                ></v-text-field>
              </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                  label="Card Type"
                  dense
                  filled
                  hide-details
                  v-model="form.cardType"
                ></v-text-field>
              </v-col>
               <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                 <v-text-field
                  label="Date of Birth"
                  dense
                  filled
                  hide-details
                  v-model="form.DOB"
                ></v-text-field>
              </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                  label="Card Registration Date"
                  dense
                  filled
                  hide-details
                  v-model="form.cardRegisterationDate"
                ></v-text-field>
              </v-col>
               <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                 <v-text-field
                  label="Card Expiry Date"
                  dense
                  filled
                  hide-details
                  v-model="form.cardExpiryDate"
                ></v-text-field>
              </v-col>
               
              
            
               <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  label="Gender"
                  dense
                  filled
                  hide-details
                  v-model="form.gender"
                ></v-text-field>
              </v-col>
            
             
             </v-row>
            </v-card-text>
           </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card>
            <v-tabs
              fixed-tabs
              v-model="tabTwo"
              active-class="deep-orange darken-2 white--text"
              next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline"
              show-arrows
            >
              <v-tab class="text-capitalize">
                <v-icon left>mdi-google-maps</v-icon> Other Information
              </v-tab>
              <v-tab class="text-capitalize">
                <v-icon left>mdi-globe-model</v-icon> System Info
              </v-tab>
              <v-tab class="text-capitalize">
                <v-icon left>mdi-briefcase-account-outline</v-icon> KYB
              </v-tab>
              <v-tab class="text-capitalize">
                <v-icon left>mdi-account-cash</v-icon> AML
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabTwo">
              <v-tab-item>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-card>
                      <!-- <v-card-title>
                      <v-subheader class="title py-0">
                        Other Information
                      </v-subheader>
                    </v-card-title> -->
                      <v-card-text>
                        <v-subheader class="subtitle-1 font-weight-medium"
                          >Geo Location</v-subheader
                        >
                        <v-divider></v-divider>
                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>ip</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.ip
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>ISO Code</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.ip
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Country</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.country_name
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                        <!--  -->
                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title
                                >State/Province</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.sub_div_name
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>District</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.district_name
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Postal Code</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.postal_code
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>

                        <!--  -->
                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Capital</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.country_capital
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Latitude</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.latitude
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Longitude</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.longitude
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>

                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Time zone</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.time_zone
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title
                                >Country curret time</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.country_curret_time
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Continent</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.continent
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>

                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>gmt offset</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.gmt_offset
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title
                                >calling code</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.calling_code
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title
                                >top level domain</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.top_level_domain
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>

                        <!--  -->
                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Languages</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.languages
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title
                                >country flag</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.country_flag
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title
                                >continent code</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.continent_code
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>

                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>currency</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.currency
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>City</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.city_name
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title
                                >continent code</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                dataReceived.message.geo_loc.continent_code
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-card>
                      <!-- <v-card-title>Syatem Info</v-card-title> -->
                      <v-card-text>
                        <v-subheader class="subtitle-1 font-weight-medium"
                          >Browser</v-subheader
                        >
                        <v-divider></v-divider>
                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Browser</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.system_info.browser
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title
                                >Operating System</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                dataReceived.message.system_info.deviceType
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Device type</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.system_info.deviceType
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>

                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Brand</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.system_info.brand
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>ASN number</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.asn_data.asn_number
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>ASN name</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.asn_data.asn_name
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>

                        <!--  -->
                        <div class="d-flex grey lighten-5">
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Network</v-list-item-title>
                              <v-list-item-subtitle>{{
                                dataReceived.message.asn_data.network
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-card>
                      <v-card-text>
                        <v-subheader class="subtitle-1 font-weight-medium">
                          Know Your Business
                        </v-subheader>
                        <v-divider></v-divider>

                        <v-data-table
                          v-model="selectedKYBMembers"
                          :headers="headers"
                          :items="dataReceived.message.kyb_data"
                          :single-select="singleSelect"
                          item-key="id"
                          show-select
                          class="elevation-0"
                        >
                          <template v-slot:item.action="{ item }">
                            <v-btn icon @click="viewDetail(item)"
                              ><v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-card>
                      <v-card-text>
                        <v-subheader class="subtitle-1 font-weight-medium">
                          Anti-Money Laundering
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="aml_headers"
                          :items="aml_data"
                          class="elevation-0"
                          :single-expand="singleExpand"
                          :expanded.sync="expanded"
                          item-key="name"
                          show-expand
                          hide-default-footer
                          @item-expanded="getIndividualAmlDetail"
                        >
                          <template v-slot:top>
                            <v-subheader class="title">
                              AML
                            </v-subheader>
                          </template>

                          <template v-slot:item.name="{ item, index }">
                            <span class="ml-2" v-if="index == 0">
                              Sanction
                            </span>
                            <span class="ml-2" v-if="index == 1"> AML </span>
                            <span class="ml-2" v-if="index == 2"> PEP </span>
                          </template>

                          <template v-slot:item.quantity="{ item, index }">
                            <span class="ml-2" v-if="index == 0">
                              {{ item.value.length }}
                            </span>
                            <span class="ml-2" v-if="index == 1">
                              {{ item.value.length }}
                            </span>
                            <span class="ml-2" v-if="index == 2">
                              {{ item.value.length }}
                            </span>
                          </template>

                          <!--  -->
                          <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="pa-0">
                              <v-card tile elevation="0">
                                <v-card-text class="px-0 py-0 my-0">
                                  <!-- sanction data table -->
                                  <v-data-table
                                    v-show="showExpendedItem == 'sanction'"
                                    v-model="selectedSanctionMembers"
                                    :headers="sanctionHeaders"
                                    :items="aml_data[0].value"
                                    :single-select="singleSelect"
                                    item-key="_id"
                                    show-select
                                    class="elevation-0 px-0 mx-0"
                                  >
                                    <template v-slot:item.action="{ item }">
                                      <v-btn
                                        @click="viewSanctionDetail(item)"
                                        icon
                                        ><v-icon>mdi-eye</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-data-table>

                                  <!-- AML data table -->
                                  <v-data-table
                                    v-show="showExpendedItem == 'aml'"
                                    v-model="selectedAMLMembers"
                                    :headers="amlHeaders"
                                    :items="aml_data[1].value"
                                    :items-per-page="-1"
                                    :single-select="singleSelect"
                                    item-key="_id"
                                    show-select
                                    class="elevation-0 px-0 mx-0"
                                  >
                                    <template v-slot:item.action="{ item }">
                                      <v-btn @click="viewAMLDetail(item)" icon
                                        ><v-icon>mdi-eye</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-data-table>

                                  <!-- pep data table -->
                                  <v-data-table
                                    v-show="showExpendedItem == 'pep'"
                                    v-model="selectedPepMembers"
                                    :headers="pepHeaders"
                                    :items="aml_data[2].value"
                                    :items-per-page="-1"
                                    :single-select="singleSelect"
                                    item-key="_id"
                                    show-select
                                    class="elevation-0 px-0 mx-0"
                                  >
                                    <template v-slot:item.action="{ item }">
                                      <v-btn @click="viewPepDetail(item)" icon
                                        ><v-icon>mdi-eye</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-data-table>
                                </v-card-text>
                              </v-card>
                            </td>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- style="height: 80vh; overflow: scroll" class="d-flex align-center" -->

    <v-overlay :value="showOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Are you sure?
        </v-card-title>
        <v-card-text>{{ dialogMessagePreview }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Disagree
          </v-btn>
          <v-btn color="green darken-1" text @click="verifyOrReject()">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- KBY Detail Dialog -->
    <v-dialog v-model="viewDetailDialog" fullscreen>
      <v-card tile>
        <v-toolbar tile class=" elevation-2" fixed color="white" height="60">
          <v-toolbar-title>
            <v-list-item class="ml-0 pl-0">
              <v-list-item-content>
                <v-list-item-title class="subtitle-1 font-weight-medium">
                  Detail
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  <span>Know Your Business</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            icon
            class="ma-0"
            color="error"
            @click="viewDetailDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle>{{
                      kyb_detail && kyb_detail.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Date of Birth</v-list-item-title>
                    <v-list-item-subtitle>{{
                      kyb_detail && kyb_detail.date_of_birth
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-list dense rounded class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Nationality</v-list-item-title>
                    <v-list-item-subtitle
                      v-for="(item, index) in kyb_detail &&
                        kyb_detail.nationality"
                      :key="index"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{ item }}</span>
                        </template>
                        <span>{{ item }}</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="5" lg="5" xl="5">
              <v-list dense rounded class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Address</v-list-item-title>
                    <v-list-item-subtitle
                      v-for="(item, index) in kyb_detail &&
                        kyb_detail.addresses"
                      :key="index"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{ item }}</span>
                        </template>
                        <span>{{ item }}</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
          </v-row>
        </v-container>
        <!-- end container -->
        <v-container fluid>
          <v-subheader class="subtitle-1 font-weight-bold"
            >Sources we find</v-subheader
          >
          <v-data-iterator
            :items="kyb_detail && kyb_detail.sources ? kyb_detail.sources : []"
            item-key="id"
          >
            <template v-slot:default="{ items }">
              <v-row>
                <v-col
                  cols="12"
                  v-for="(item, index) in items"
                  :key="item.id"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                  <!--  -->
                  <v-hover v-slot="{ hover }" open-delay="30" close-delay="30">
                    <v-card :elevation="hover ? 4 : 2">
                      <v-card-text class="pa-1">
                        <v-card-title
                          class="subtitle-1 font-weight-medium pt-2 pb-4"
                        >
                          Dataset Name
                          <!-- <v-spacer></v-spacer>
  <v-chip label  color="primary" >{{index + 1}}</v-chip> -->
                        </v-card-title>
                        <v-card-subtitle class="py-0">
                          {{ item.dataset_name }}
                        </v-card-subtitle>
                        <v-divider
                          class="grey lighten-3 mt-3 ml-4 mr-4"
                        ></v-divider>
                        <v-card-title
                          class="subtitle-1 pt-3 font-weight-medium pb-4"
                          >Compnay</v-card-title
                        >
                        <v-card-subtitle class="py-0 mb-4">
                          {{ item.id }}
                        </v-card-subtitle>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- AML Sanction Detail Dialog -->
    <v-dialog v-model="almSanctionDetailDialog" fullscreen>
      <v-card>
        <v-toolbar tile class=" elevation-2" fixed color="white" height="60">
          <v-toolbar-title>
            <v-list-item class="ml-0 pl-0">
              <v-list-item-content>
                <v-list-item-title class="subtitle-1 font-weight-medium">
                  Detail
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  <span>Anti-Money Laundering</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            icon
            class="ma-0"
            color="error"
            @click="almSanctionDetailDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlSanctionDetail && amlSanctionDetail.Name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>DOB</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlSanctionDetail && amlSanctionDetail["Date of Birth"]
                        ? amlSanctionDetail &&
                          amlSanctionDetail["Date of Birth"]
                        : "N/A"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Country</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlSanctionDetail && amlSanctionDetail.Country
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Place of Birth</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlSanctionDetail && amlSanctionDetail["Place of Birth"]
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Nationality </v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlSanctionDetail && amlSanctionDetail.Nationality
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Remarks </v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlSanctionDetail && amlSanctionDetail.Remarks
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Identity Number </v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlSanctionDetail && amlSanctionDetail["Identity Number"]
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Crime</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlSanctionDetail && amlSanctionDetail.Crime
                        ? amlSanctionDetail && amlSanctionDetail.Crime
                        : "N/A"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Address</v-list-item-title>
                    <v-list-item-subtitle
                      v-for="(item, index) in amlSanctionDetail &&
                      amlSanctionDetail.Addresses
                        ? amlSanctionDetail.Addresses
                        : []"
                      :key="index"
                      ><v-icon small>
                        mdi-arrow-right-bold-circle-outline
                      </v-icon>
                      {{ item }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Url</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlSanctionDetail && amlSanctionDetail.Url
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- AML PEP Detail Dialog -->
    <v-dialog v-model="almPepDetailDialog" fullscreen>
      <v-card v-if="amlPepDetail">
        <v-toolbar tile class=" elevation-2" fixed color="white" height="60">
          <v-toolbar-title>
            <v-list-item class="ml-0 pl-0">
              <v-list-item-content>
                <v-list-item-title class="subtitle-1 font-weight-medium">
                  Detail
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  <span>Anti-Money Laundering</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            icon
            class="ma-0"
            color="error"
            @click="almPepDetailDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlPepDetail && amlPepDetail.Name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>DOB</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlPepDetail && amlPepDetail["Date of Birth"]
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Country</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlPepDetail && amlPepDetail.Country
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Gender</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlPepDetail && amlPepDetail.Gender
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Chamber</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlPepDetail && amlPepDetail.Chamber
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Party</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlPepDetail && amlPepDetail.Party
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Area</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlPepDetail && amlPepDetail.Area
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Image Url</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlPepDetail && amlPepDetail["Image Url"]
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Image Url</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlPepDetail && amlPepDetail["Image Url"]
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Social</v-list-item-title>
                    <v-list-item-subtitle
                      ><v-icon small>mdi-email-outline</v-icon>
                      {{
                        amlPepDetail && amlPepDetail.Social.email
                          ? amlPepDetail.Social.email
                          : "N/A"
                      }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      ><v-icon small>mdi-facebook-box</v-icon>
                      {{
                        amlPepDetail && amlPepDetail.Social.twitter
                          ? amlPepDetail.Social.twitter
                          : "N/A"
                      }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      ><v-icon small>mdi-facebook-box</v-icon>
                      {{
                        amlPepDetail && amlPepDetail.Social.facebook
                          ? amlPepDetail.Social.facebook
                          : "N/A"
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- AML AML Detail Dialog -->
    <v-dialog v-model="amlDetailDialog" fullscreen>
      <v-card tile>
        <v-toolbar tile class=" elevation-2" fixed color="white" height="60">
          <v-toolbar-title>
            <v-list-item class="ml-0 pl-0">
              <v-list-item-content>
                <v-list-item-title class="subtitle-1 font-weight-medium">
                  Detail
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  <span>Know Your Business</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            icon
            class="ma-0"
            color="error"
            @click="amlDetailDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle>{{
                      amlDetailData && amlDetailData.Name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Country</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ amlDetailData && amlDetailData.Country }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-list rounded dense class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Source</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ amlDetailData && amlDetailData.Source }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-list dense rounded class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>Address</v-list-item-title>
                    <v-list-item-subtitle
                      v-for="(item, index) in amlDetailData &&
                        amlDetailData.Addresses"
                      :key="index"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{ item }}</span>
                        </template>
                        <span>{{ item }}</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid>
          <!--  -->
          <!-- <v-divider class="mt-6"></v-divider> -->
          <v-subheader class="subtitle-1 font-weight-bold"
            >Entities</v-subheader
          >
          <!-- <v-divider class="mb-4"></v-divider> -->

          <v-data-iterator
            :items="
              amlDetailData && amlDetailData.Entities.length
                ? amlDetailData.Entities
                : []
            "
            item-key="id"
          >
            <template v-slot:default="{ items }">
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  v-for="(item, index) in items"
                  :key="item.id"
                >
                  <!--  -->
                  <v-hover v-slot="{ hover }" open-delay="30" close-delay="30">
                    <v-card :elevation="hover ? 4 : 2">
                      <v-card-text class="pa-1">
                        <v-card-title
                          class="subtitle-1 font-weight-medium pt-2 pb-4"
                        >
                          Name
                          <v-spacer></v-spacer>
                          <v-chip label color="primary">{{ index + 1 }}</v-chip>
                        </v-card-title>
                        <v-card-subtitle class="py-0">
                          {{ item.Name }}
                        </v-card-subtitle>
                        <v-divider
                          class="grey lighten-3 mt-3 ml-4 mr-4"
                        ></v-divider>
                        <v-card-title
                          class="subtitle-1 pt-3 font-weight-medium pb-4"
                          >Role</v-card-title
                        >
                        <v-card-subtitle class="py-0 mb-4">
                          {{ item.Role }}
                        </v-card-subtitle>

                        <v-divider
                          class="grey lighten-3 mt-3 ml-4 mr-4"
                        ></v-divider>
                        <v-card-title
                          class="subtitle-1 pt-3 font-weight-medium pb-4"
                          >Start Date</v-card-title
                        >
                        <v-card-subtitle class="py-0 mb-4">
                          {{ item.Start_date }}
                        </v-card-subtitle>

                        <v-divider
                          class="grey lighten-3 mt-3 ml-4 mr-4"
                        ></v-divider>
                        <v-card-title
                          class="subtitle-1 pt-3 font-weight-medium pb-4"
                          >End Date</v-card-title
                        >
                        <v-card-subtitle class="py-0 mb-4">
                          {{ item.End_Date }}
                        </v-card-subtitle>

                        <v-divider
                          class="grey lighten-3 mt-3 ml-4 mr-4"
                        ></v-divider>
                        <v-card-title
                          class="subtitle-1 pt-3 font-weight-medium pb-4"
                          >Jurisdiction</v-card-title
                        >
                        <v-card-subtitle class="py-0 mb-4">
                          {{ item.Jurisdiction }}
                        </v-card-subtitle>

                        <v-divider
                          class="grey lighten-3 mt-3 ml-4 mr-4"
                        ></v-divider>
                        <v-card-title
                          class="subtitle-1 pt-3 font-weight-medium pb-4"
                          >Status</v-card-title
                        >
                        <v-card-subtitle class="py-0 mb-4">
                          {{ item.Status }}
                        </v-card-subtitle>

                        <v-divider
                          class="grey lighten-3 mt-3 ml-4 mr-4"
                        ></v-divider>
                        <v-card-title
                          class="subtitle-1 pt-3 font-weight-medium pb-4"
                          >Data Source</v-card-title
                        >
                        <v-card-subtitle class="py-0 mb-4">
                          {{ item.Data_Source }}
                        </v-card-subtitle>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
export default {
  props: ["showComponent", "dataReceived"],
  components: {
    "inner-image-zoom": InnerImageZoom,
  },
  data() {
    return {
      tabTwo: null,
      tab: null,
      selected: [],
      expanded: [],
      selectedKYBMembers: [],
      selectedAMLMembers: [],
      selectedSanctionMembers: [],
      selectedPepMembers: [],
      singleExpand: true,
      almPepDetailDialog: false,
      almSanctionDetailDialog: false,
      amlSanctionDetail: null,
      amlPepDetail: null,
      showExpendedItem: "",
      aml_data: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "DOB", value: "date_of_birth" },
        { text: "View Detail", value: "action" },
      ],
      aml_headers: [
        { text: "Name", value: "name" },
        { text: "Quantity", value: "quantity" },
      ],
      amlHeaders: [
        { text: "Name", value: "Name" },
        { text: "Country", value: "Country" },
        { text: "View Detail", value: "action" },
      ],
      sanctionHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Date of Birth", value: "dob" },
        { text: "Country", value: "Country" },
        { text: "Place of Birth", value: "place_of_birth" },
        { text: "View Detail", value: "action" },
      ],
      pepHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Date of Birth", value: "dob" },
        { text: "Country", value: "Country" },
        { text: "Gender", value: "Gender" },
        { text: "View Detail", value: "action" },
      ],
      kyb_detail: null,
      amlDetailData: null,
      showOverlay: false,
      amlDetailDialog: false,
      viewDetailDialog: false,
      dialog: false,
      tab: null,
      // dialogMessagePreview: "You want to verify or Reject this person?",
      message: "",
      button1: "Pause",
      button2: "Pause",
      button3: "Pause",
      form: {
        frontCardImage: "",
        backCardImage: "",
        frontImage: "",
        backImage: "",
        faceImage: "",
        personName: "",
        fatherName: "",
        CNIC: "",
        DOB: "",
        cardRegisterationDate: "",
        cardExpiryDate: "",
        gender: "",
        cardType: "",
        imageName: "",
        remarks: "",
        verificationStatus: "",
        distance: "",
        maxThreshhold: "",
      },
      rejectItems: [
        "Face is different",
        "Image is Blur",
        "Not Similar",
        "Rejected",
        "No Data Extracted",
        "Other",
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserId"]),
    isAllowedToVerify() {
      let data = this.dataReceived.message.checks;
      for (let key of Object.keys(data)) {
        if (!data[key]) {
          return false;
        }
      }
      return true;
    },
    dialogMessagePreview() {
      if (this.message === "rejectMessage") {
        return "You want to Reject this person?";
      } else {
        return "You want to Verify this person?";
      }
    },
  },
  methods: {
    ...mapActions("deo", ["storeUserData"]),
    viewSanctionDetail(item) {
      this.almSanctionDetailDialog = true;
      this.amlSanctionDetail = item;
    },
    viewAMLDetail(amlItem) {
      this.amlDetailDialog = true;
      this.amlDetailData = amlItem;
    },
    viewPepDetail(item) {
      this.almPepDetailDialog = true;
      this.amlPepDetail = item;
    },
    getAMlData() {
      let data = this.dataReceived.message.aml_data;
      this.aml_data = [];
      for (let [key, value] of Object.entries(data)) {
        this.aml_data.push({
          name: key,
          value: value,
        });
      }
    },
    getIndividualAmlDetail({ item }) {
      this.showExpendedItem = item.name.toLowerCase();
    },
    viewDetail(item) {
      this.viewDetailDialog = true;
      this.kyb_detail = item;
    },
    verifyOrReject() {
      // @click="sendVerifiedData(form.remarks)"
      // @click="sendVerifiedData(['verified'])"
      if (this.message === "rejectMessage") {
        console.log("reject called");
        // this.dialogMessagePreview = "You want to reject this verification?"
        this.sendVerifiedData(this.form.remarks);
      } else {
        console.log("verify called");
        // this.dialogMessagePreview = "You want to verify this verification?"
        this.sendVerifiedData(["verified"]);
      }
    },
    sendVerifiedData(re) {
      let obj = {
        kyb_ids: this.selectedKYBMembers,
        sanction_ids: this.selectedSanctionMembers,
        pep_ids: this.selectedPepMembers,
        aml_ids: this.selectedAMLMembers,
        customer_id: this.dataReceived.message.customer_id,
        person_name: this.form.personName,
        father_name: this.form.fatherName,
        id_card_number: this.form.CNIC,
        date_of_birth: this.form.DOB,
        type: this.form.cardType,
        gender: this.form.gender,
        registration_date: this.form.cardRegisterationDate,
        expiry_date: this.form.cardExpiryDate,
        ced_id: this.dataReceived.message.ced_id,
        image_id: this.dataReceived.message.image_id,
        deo_remarks: re ? re : ["Person name is present in either KYB or AML"],
        face_data: this.dataReceived.message.results,
        service: "KYC",
        country: this.dataReceived.message.country,
        deo_id: this.getUserId,
        geoloc: this.dataReceived.message.geoloc,
        asn_data: this.dataReceived.message.asn_data,
        system_info: this.dataReceived.message.system_info,
        business_id: this.dataReceived.message.business_id,
        branch_id: this.dataReceived.message.branch_id,
        user_id: this.dataReceived.message.user_id,
        org_fullname: this.dataReceived.message.full_name,
        org_dob: this.dataReceived.message.dob,
        org_expiry: this.dataReceived.message.expiry_date,
        deo_id: this.dataReceived.message.deo_id,
        channel_id: this.dataReceived.message.channel_id,
      };
      this.storeUserData(obj).then(async (res) => {
        // console.log('storeUserData: ', res)
        await this.$emit("titleEmitted", { title: "Verify Data", svg: "" });
        await this.$emit("receivingData", res.data.result_url);
        await this.showComponent("default");
        this.showOverlay = false;
      });
    },
    pauseFaceImage() {
      let a = document.getElementById("face");
      if (this.button3 == "Pause") {
        this.form.faceImage = this.dataReceived.message.face_image;
        this.button3 = "Play";
      } else {
        this.form.faceImage = this.dataReceived.message.face_gif;
        this.button3 = "Pause";
      }
    },
    pauseFrontImage() {
      let b = document.getElementById("front");
      if (this.button1 == "Pause") {
        this.form.frontCardImage = this.dataReceived.message.id_card;
        this.button1 = "Play";
      } else {
        this.form.frontCardImage = this.dataReceived.message.front_card_gif;
        this.button1 = "Pause";
      }
    },
    pauseBackImage() {
      let c = document.getElementById("back");
      if (this.button2 == "Pause") {
        this.form.backCardImage = this.dataReceived.message.id_card_back;
        this.button2 = "Play";
      } else {
        this.form.backCardImage = this.dataReceived.message.back_card_gif;
        this.button2 = "Pause";
      }
    },
  },
  mounted() {
    this.showOverlay = true;
    this.form.personName = this.dataReceived.message.extracted_data.Person_Name;
    this.form.fatherName = this.dataReceived.message.extracted_data.Father_Name;
    this.form.CNIC = this.dataReceived.message.extracted_data.ID_Card_Number;
    this.form.DOB = this.dataReceived.message.extracted_data.Date_of_Birth;
    this.form.cardRegisterationDate = this.dataReceived.message.extracted_data.Card_Registration_Date;
    this.form.gender = this.dataReceived.message.extracted_data.Gender;
    this.form.cardExpiryDate = this.dataReceived.message.extracted_data.Card_Expiry_Date;
    this.form.frontCardImage = this.dataReceived.message.front_card_gif;
    this.form.backCardImage = this.dataReceived.message.back_card_gif;
    this.form.faceImage = this.dataReceived.message.face_gif;
    this.form.frontImage = this.dataReceived.message.id_card;
    this.form.backImage = this.dataReceived.message.id_card_back;
    this.form.cardType = this.dataReceived.message.extracted_data.Card_Type;
    (this.form.verificationStatus = this.dataReceived.message.results.verified),
      (this.form.distance = this.dataReceived.message.results.distance),
      (this.form.maxThreshhold = this.dataReceived.message.results.max_threshold_to_verify),
      (this.showOverlay = false);
    this.$emit("titleEmitted", {
        title: "Driving License",
        svg: "drivingLicense.svg",
      });

    // load aml data
    this.getAMlData();
  },
};
</script>

<style lang="scss" scoped>
#face,
#front,
#back {
  border: 1px solid black;
  padding: 5px;
  color: gray;
  border-radius: 5px;
}
.page-header {
  font-weight: 500;
  font-size: 18px;
}
.user-header {
  font-weight: 500;
  font-size: 14px;
  height: 16px;
}
.img-header {
  font-weight: 500;
  font-size: 16px;
  height: 20px;
}
.extracted-img {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
