<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
                <v-btn
                bottom
                color="primary"
                dark
                v-on="on"
                fab
                fixed
                right
                 @click="performAction('', 'create', '')"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
      </template>
      <span>Create New Role</span>
     </v-tooltip>
    <v-row class="d-flex align-center mt-10">
      <v-col cols="12" sm="12" md="8" lg="9" xl="8" class="mx-auto">
        <v-card>
          <v-card-text>
            <v-subheader class="title font-weight-medium pa-0 ma-0"
              >Roles</v-subheader
            >
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="12" xl="12">
                <v-data-table
                :headers="headers"
                :items="getRoles"
                :search="search"
                :loading="fetching"
                loading-text="Loading... Please wait"
                class="elevation-0"
                >
                <template v-slot:item.actions="{ item }">
                    <v-menu
                      v-model="item.showActions"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary">
                          <v-icon>mdi-dots-vertical-circle-outline</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-list>
                          <v-subheader>Actions</v-subheader>
                          <v-divider></v-divider>
                          <v-list-item-group color="primary">
                            <v-list-item
                              v-for="(acti, i) in actionIcon"
                              :key="i"
                              @click="
                                performAction(item.branchid, acti.text, item)
                              "
                            >
                              <v-list-item-icon>
                                <v-icon
                                  v-text="acti.icon"
                                  @click="
                                    performAction(
                                      item.branchid,
                                      acti.text,
                                      item
                                    )
                                  "
                                  small
                                ></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="acti.text"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Are You Sure? </v-card-title>

        <v-card-text>
          Are you sure you want to delete this Role.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            Disagree
          </v-btn>
          <v-btn color="green darken-1" text @click="deletetingRole">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      color="success"
      outlined
    >
      {{ getRoleSnackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>
            mdi-cancel
          </v-icon>
        </v-btn>
      </template>

    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            search: '',
            fetching: true,
            overlay: false,
            snackbar: false,
            dialog: false,
            getAllRoles: [],
            roleDetails: null,
            headers: [
                { text: "Role Name", value: "role_name" },
                { text: "Actions", value: "actions" },
            ],
            actionIcon: [
                { text: "View", icon: "mdi-eye" },
                { text: "Edit", icon: "mdi-lead-pencil" },
                { text: "Delete", icon: "mdi-delete" },
            ],
        }
    },
    created(){
      this.fetchingAllRoles();
      if(this.getRoleSnackMessage != null){
        this.snackbar = true;
      }
    },
    beforeDestroy(){
    this.$store.commit('roleMenuPermission/setRoleSnackMessage', null)
    },
    computed: {
      ...mapGetters("roleMenuPermission", ["getRoleSnackMessage", "getRoles"])
    },
    methods: {
      ...mapActions("roleMenuPermission", ["allRoles", "deleteRole"]),
    fetchingAllRoles(){
      this.allRoles().then(res => {
        console.log('res', res.data)
        this.fetching = false;
        this.$store.commit("roleMenuPermission/setRoles", res.data.data)

      }).catch(e => {
        console.log('error', e)
        //  this.$store.commit('roleMenuPermission/setRoleSnackMessage', 'Error Fetching Roles')

      })
    },
    performAction(roleId, actionType, data) 
    {
      // console.log('id', roleId )
      // console.log('Action', actionType )
      // console.log('data', data )
      this.roleDetails = data
      if(actionType.toLowerCase() == 'create'){
        this.$router.push({
          name: 'Roles',
          params: {showComponent: 'create'}
        })
      }
      else if(actionType.toLowerCase() == "delete"){
        this.dialog = true;
      }
      else if(actionType.toLowerCase() == "view"){
        this.$emit('roleDetails', this.roleDetails)
        this.$router.push({
          name: "Roles",
          params: { showComponent: "view" },
        })
      }
      else if(actionType.toLowerCase() == "edit")
      {
        this.$emit('roleDetails', this.roleDetails)
        this.$router.push({
          name: "Roles",
          params: { showComponent: "edit" },
        })
      }
    },
    deletetingRole(){
      // console.log('Role', this.roleDetails);
      this.dialog = false;
      this.overlay = true;
      this.deleteRole(this.roleDetails.role_id).then(async (res) => {
        // console.log('res', res) 
        await this.$store.commit('roleMenuPermission/setRoleSnackMessage', 'Role Deleted Succesfully')
        await this.fetchingAllRoles();
        this.overlay = false;
        this.snackbar = true;
         
      }).catch(e => {
        console.log('error', e)
        this.$store.commit('roleMenuPermission/setRoleSnackMessage', 'Error Deleting Role')
        this.overlay = false;
      })
    }
    }
};
</script>

<style lang="scss" scoped>
</style>