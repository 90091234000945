<template>
  <div>
    <v-container>
      <div class="d-flex">
        <h1 class="ma-2">Dashboard</h1>
        <v-spacer></v-spacer>
        <div v-if="getUserRole == 'SuperAdmin'">

        
          <v-btn
            class="ma-2"
            color="info"
            @click="showActiveDeo"
            :disabled="activeDisabler"
          >
          Active Deo
          </v-btn>
          <v-btn
            class="ma-2"
            :disabled="!activeDisabler"
            color="error"
            @click="showDeactivateDeo"
          >
          InActive Deo
          </v-btn>
        </div>
      </div>
      <v-data-table
        v-if="getUserRole == 'SuperAdmin'"
        :headers="headers"
        :loading="gettingDeoListLoader"
        :items="deoInfo.results"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :items-per-page="20"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-spacer></v-spacer>
          <v-btn
            :loading="gettingDeoListLoader"
            @click="getDeoList"
            class="ma-2"
            fab
            dark
            small
            color="success"
          >
            <v-icon dark> mdi-refresh </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.deo_status="{ item }" v-if="deoInfo.type == 'activated'">
          <v-chip :color="getColor(item.deo_status)" dark v-if="deoInfo.type == 'activated'">
            {{ item.deo_status }}
          </v-chip>
        </template>

        <template v-slot:[`item.action`]="{ item }" v-if="deoInfo.type == 'activated'">
          <v-hover v-slot="{ hover }">
            <v-btn
              color="primary"
              :elevation="hover ? 8 : 0"
              outlined
              rounded
              depressed
              class="text-capitalize"
              small
              @click="showDeoDetails(item)"
              >Details</v-btn
            >
          </v-hover>
        </template>
        <template v-slot:[`item.action2`]="{ item }" v-if="deoInfo.type == 'activated'">
          <v-hover v-slot="{ hover }">
            <!-- <span class="ml-4"> Available</span> -->
            <v-switch
              hide-details
              @change="deoStatus(item)"
              style="width:137px"
              inset
            >
            </v-switch>
          </v-hover>
        </template>

        <template v-slot:[`item.verificationList`]="{ item }">
          <v-menu
            v-model="item.showDetails"
            :close-on-content-click="false"
            :nudge-width="200"
            transition="slide-y-transition"
            bottom
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" >
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <v-card>
              <v-list subheader>
                <v-subheader>Deo Actions</v-subheader>
                <!-- <v-list-item
                  v-for="(verificationTypeStatus,i) in item.deo_id?item:[]"
                  :key="i"
                > -->

                <!-- <v-list-item-content>
                    <v-list-item-title >{{verificationTypeStatus.deo_id}}</v-list-item-title>
                  </v-list-item-content> -->

                <v-btn color="error" class="ma-2" v-if="deoInfo.type == 'activated'" @click="beforeDeleteDialog(item.deo_id)">Delete</v-btn>
                <v-btn color="success" class="ma-2" v-if="deoInfo.type == 'deactivated'" @click="beforeDeleteDialog(item.deo_id)">Activate</v-btn>
                <!-- </v-list-item> -->
              </v-list>
          </v-card>

          </v-menu>
        </template>
      </v-data-table>
      <!-- {{getUserDetails}} -->
      <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Are You Sure? </v-card-title>
        <v-card-text v-if="deoInfo.type == 'activated'" >
          Are you sure you want to deactivated this DEO.
        </v-card-text>
        <v-card-text v-else>
          Are you sure you want to activate this DEO.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog = false">
            Disagree
          </v-btn>

          <v-btn color="green darken-1" v-if="deoInfo.type == 'activated'" text @click="DeleteDeo">
            Agree
          </v-btn>
          <v-btn v-else color="green darken-1" text @click="ActivateDeo">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      gettingDeoListLoader: true,
      label: "New",
      headers: [
        { text: "First Name", value: "deo_first_name" },
        { text: "Last Name", value: "deo_last_name" },
        {text: "User Name", value: "deo_username"},
        { text: "Region", value: "deo_region" },
        { text: "Country", value: "deo_country" },
        { text: "City", value: "deo_city" },
        { text: "Status", value: "deo_status" },
        { text: "Change Status", value: "action2" },
        { text: "Details", value: "action" },
        { text: "Action", value: "verificationList" },
      ],
      deoInfo: [],
      dialog: false,
      activeDisabler: true,
      deoId: ''
    };
  },
  created() {
    this.getDeoList();
  },
  computed: {
    ...mapGetters("auth", ["getUserDetails", "getUserRole"]),
  },
  methods: {
    ...mapActions("deo", ["getAllDEOInfo", "updateDeoStatus", "deactivateDeo", "showDeactivatedDeo", "activateDeo"]),
    beforeDeleteDialog(item){
      this.dialog = true;
      this.deoId = item;
      // console.log(item, 'item')
    },
    
    DeleteDeo(){
      // console.log(item,'item')
      this.dialog = false;
      this.deactivateDeo(this.deoId).then(async (res) => {
        // console.log('Delted', res)
        await this.getDeoList();
      })
      .catch(e => {
        console.log('error:', e)
      })
    },
    ActivateDeo(){
      this.dialog = false;
      // console.log('doe id', this.deoId)
      this.activateDeo(this.deoId).then(async (res) => {
        // console.log('activated res', res);
        await this.showDeactivateDeo();
      }).catch(e => {
        console.log('error', e)
      });
    },
    showDeactivateDeo(){
      // this.headers.splice(6,3)
      this.headers = [
        { text: "First Name", value: "deo_first_name" },
        { text: "Last Name", value: "deo_last_name" },
        {text: "User Name", value: "deo_username"},
        { text: "Region", value: "deo_region" },
        { text: "Country", value: "deo_country" },
        { text: "City", value: "deo_city" },
        { text: "Action", value: "verificationList" },
      ]
      // console.log(this.headers,  "headers:")
      this.activeDisabler = false;
      this.gettingDeoListLoader = true;
      this.showDeactivatedDeo().then(res => {
      // console.log('get activated Deo', res);
      this.gettingDeoListLoader = false;
        this.deoInfo = res.data; 
      }).catch(e => {
        console.log('error', e);
      this.gettingDeoListLoader = false;
      })
    },
    showActiveDeo(){
      this.activeDisabler = true;
       this.headers = [
        { text: "First Name", value: "deo_first_name" },
        { text: "Last Name", value: "deo_last_name" },
        {text: "User Name", value: "deo_username"},
        { text: "Region", value: "deo_region" },
        { text: "Country", value: "deo_country" },
        { text: "City", value: "deo_city" },
        { text: "Status", value: "deo_status" },
        { text: "Change Status", value: "action2" },
        { text: "Details", value: "action" },
        { text: "Action", value: "verificationList" },

      ],
      this.getDeoList();

    },
    getDeoList() {
      if (this.getUserRole == "SuperAdmin") {
        this.deoInfo = [];
        this.gettingDeoListLoader = true;
        this.getAllDEOInfo()
          .then((res) => {
            this.gettingDeoListLoader = false;
            // console.log("get all deo info response", res);         
            this.deoInfo = res.data;
            // console.log('response:', res.data);
            this.deoInfo.results.map(val => {
              val.showDetails = false
            })
          })
          .catch((err) => {
            this.gettingDeoListLoader = false;
            console.log('error', err)
          });
      }
    },
    deoStatus(item){
      // console.log(item)
      if(item.deo_status == 'Not Available' || item.deo_status == 'Busy') {
        this.updateDeoStatus({ status: "Free",  deo_id: item.deo_id }).then(async (res) => {
          // console.log(res, 'res')
          await this.getDeoList();
        });
      } else {
        this.updateDeoStatus({ status: "Not Available", deo_id: item.deo_id }).then(async (res) => 
        {
          // console.log(res, 'res')
          await this.getDeoList();
        }
        );
      }
    },
    getColor(status) {
      if (status.toLowerCase() == "not available") return "red";
      else if (status.toLowerCase() == "free") return "green";
      else return "orange";
    },
    showDeoDetails(item){
      // console.log(item, 'item:')
      this.$store.commit('deo/setSingleDeoDetails', item)
      this.$router.push({
        name: 'deoDetails'
      })
    }
  },
};
</script>

<style lang="scss" scoped>
</style>