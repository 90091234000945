<template>
  <div>
      <v-navigation-drawer width="290" clipped right app>
     <template v-slot:prepend>
        <v-subheader class="subtitle-1 font-weight-medium">User Detail</v-subheader>
      </template>

      <v-divider></v-divider>
        <v-list>
        <v-list-item>
              <v-list-item-content>     
                <v-list-item-subtitle class="subtitle-2">Full Name</v-list-item-subtitle>
                 <v-list-item-title class="font-weight-medium">{{form.fullName}} </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
               
                  <v-list-item>
              <v-list-item-content>     
                <v-list-item-subtitle class="subtitle-2">User Address</v-list-item-subtitle>
                 <v-list-item-title class="font-weight-medium"> {{ form.address }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-subheader class="subtitle-2 font-weight-medium">Rejection Remarks</v-subheader>
        <div class="pa-4">
          <v-select
                      v-model="form.remarks"
                      :items="rejectItems"
                      filled
                      label="Rejection Remarks"
                      multiple
                    >
                      <template v-slot:prepend-item>
                          <v-card flat>
            <v-card-text class="pa-2">
               <v-text-field
                  label="Other Reason"
                  v-model="form.remarks"
                  filled
                  hide-details="auto"
                  single-line
                ></v-text-field>
            </v-card-text>
           </v-card>
                    
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-select>
        </div>
                     <template v-slot:append>
                    <div class="px-5 py-3 grey lighten-4">
                      <v-btn
                  class="text-capitalize primary"
                color="primary"
                  @click="sendVerifiedData(['verified'])"
                  > <v-icon left>mdi-check-decagram</v-icon>Verify
                </v-btn>
                          <v-btn
                    class="text-capitalize mr-0 float-right"
          color="error"
                  @click="sendVerifiedData(form.remarks)"
                  :disabled="form.remarks == ''"
                  ><v-icon left>mdi-close-octagon</v-icon>  Reject</v-btn
                >
                
                    </div>
                     </template>
      </v-navigation-drawer>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4" md="4" lg="4" xl="4">
             <v-hover v-slot="{ hover }">
                <v-card rounded :elevation="hover ? 6 : 2">
                     <v-card-title class="pt-3 pb-2 subtitle-1 font-weight-medium">
             Address Video
            </v-card-title>
                    <v-divider></v-divider>
                  <v-card-text>
                  <div class="text-center">
                      <!-- <img
                    :src="`${form.addressImage}`"
                    alt=""
                    width="100%"
                    height="400px"
                    class="extracted-img"
                  /> -->
                  <video
                    width="100%"
                    height="250px"
                    class="extracted-img"
                    controls
                    loop
                  >
                    <source :src="`${form.addressImage}`" type="video/mp4">
                    <source  :src="`${form.addressImage}`" type="video/webm">
                    <source  :src="`${form.addressImage}`" type="video/ogg">
                    Video tag is not supported in this browser.
                  </video>
                     
                  </div>
                   <!-- <v-btn @click="pauseVideo" c icon>
                    <v-icon dark v-if="button1 == 'Play'" medium color="primary"
                      >mdi-play-circle</v-icon
                    >
                    <v-icon dark v-if="button1 == 'Pause'" medium color="error"
                    >mdi-pause-circle</v-icon>
                  </v-btn> -->
                  </v-card-text>
                </v-card>
                   </v-hover>
          
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="8" xl="8">
            <v-card>
              <v-card-title class="pt-3 pb-2 subtitle-1 font-weight-medium">
              Extracted Details
            </v-card-title>
            <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      label="Person Name"
                      dense
                      v-model="form.fullName"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      label="Address"
                      dense
                      filled
                      v-model="form.address"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                    filled
                    label="DEO Remarks"
                    v-model="form.remarks"
                    rows="6"
                    no-resize
                    hide-details="auto"
                  ></v-textarea>
                  
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
            </v-row>
              </v-container>
  
    <v-overlay :value="showOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["showComponent", "dataReceived"],
  computed: {
    ...mapGetters("auth", ["getUserId"]),
  },
  data() {
    return {
      showOverlay: false,
      rejectItems: [
        "Face is different",
        "Image is Blur",
        "Not Similar",
        "Rejected",
        "No Data Extracted",
        "Other",
      ],
      form: {
        fullName: "",
        addressImage: "",
        address: "",
        remarks: "",
      },
      button1: "Pause",
    };
  },
  methods: {
    ...mapActions("deo", ["storeAddress"]),
    sendVerifiedData(re) {
      console.log("Data Recievied: me::", this.dataReceived.message);
      let obj = {
        customer_id: this.dataReceived.message.customer_id,
        image_id: this.dataReceived.message.image_id,
        geoloc: this.dataReceived.message.geoloc,
        asn_data: this.dataReceived.message.asn_data,
        system_info: this.dataReceived.message.system_info,
        full_name: this.form.fullName,
        address: this.form.address,
        deo_remarks: re,
        deo_id: this.getUserId,
        business_id: this.dataReceived.message.business_id,
        address_id: this.dataReceived.message.address_id,
        ced_id: this.dataReceived.message.ced_id,
        country: this.dataReceived.message.country,
        branch_id: this.dataReceived.message.branch_id,
        user_id: this.dataReceived.message.user_id,
        deo_id: this.dataReceived.message.deo_id,
        channel_id: this.dataReceived.message.channel_id,
      };
      this.storeAddress(obj).then(async (res) => {
        console.log("storeAaddressData;: ", res);
        await this.$emit('titleEmitted', {title: 'Verify Data', svg: ''})
        await this.$emit("receivingData", res.data.result_url);
        await this.showComponent("default");
        this.showOverlay = false;
      });
    },
    pauseVideo() {
      // let a = this.$refs.myBtn
      let b = document.getElementById("pau");
      // console.log(b.innerHTML)
      if (this.button1 == "Pause") {
        this.form.addressImage = this.dataReceived.message.document_image;
        this.button1 = "Play";
      } else {
        this.form.addressImage = this.dataReceived.message.document_gif;
        this.button1 = "Pause";
      }
    },
  },
  mounted() {
    this.showOverlay = true;
    this.form.fullName = this.dataReceived.message.full_name;
    this.form.addressImage = this.dataReceived.message.document_gif;
    this.form.address = this.dataReceived.message.address;
    this.showOverlay = false;
    // const image = ''
    this.$emit("titleEmitted", {
      title: "Address Verificaiton",
      svg: "documentVerification.svg",
    });
  },
};
</script>


<style lang="scss" scoped>
#pau {
  border: 1px solid black;
  padding: 5px;
  color: gray;
  border-radius: 5px;
}
.img-header {
  font-weight: 500;
  font-size: 16px;
  height: 20px;
}
.extracted-img {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>

