<template>
  <div>
    <v-row class="d-flex align-center mt-10">
      <v-col cols="12" sm="12" md="8" lg="9" xl="8" class="mx-auto">
        <v-card>
          <v-card-text>
            <v-subheader class="title font-weight-medium pa-0 ma-0"
              >New Role</v-subheader
            >
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="roleName"
                  label="Role Name"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-subheader
              class="font-weight-medium pa-0 ma-0"
              style="height: 20px"
            >
              All Menus
            </v-subheader>
            <!-- <div class="d-flex">
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto"
                  v-for="(menu, index) in componentMenus.data"
                  :key="menu.meniuid"
                >
                  <v-card class="mx-auto align-left my-5" width="400">
                    <v-card-title class="title">
                      {{ menu.menuitemsname }}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item
                        v-for="(mp, i) in menu.permissions"
                        :key="mp.id"
                      >
                        <v-list-item-title>{{
                          mp.permission
                        }}</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                            :value="mp"
                            v-model="myValue[index]"
                            class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div> -->
            <div class="d-flex">
              <v-row>
                <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto">
                
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Dashboard</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.insert"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
                  <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto">
                
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Deo</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="deoPermissions.insert"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="deoPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="deoPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="deoPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
                  <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto">
                
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Pending Verifications</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="pendingPermissions.insert"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="pendingPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="pendingPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="pendingPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
              </v-row>
            </div>

            <div class="d-flex">
              <v-row>
                <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto"
                >
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Roles</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.insert"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
              </v-row>
            </div>
            
          </v-card-text>
          <!-- {{this.componentMenus.data}} -->
          <v-card-actions class="ml-2 mr-2">
            <v-btn text @click="addRole" :disabled="roleName == ''">
              <v-icon left> mdi-content-save </v-icon>Save
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn color="error" text class="ml-2" @click="viewRolesComponent"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      myValue: [],
      roleName: "",
      overlay: false,
      checkbox: false,
      componentMenus:
      {
        data: {},
      },
      menuPermissions: [
        { permission: "Insert Permission" },
        { permission: "Display Permission" },
        { permission: "Update Permission" },
        { permission: "Delete Permission" },
      ],
      dashboardPermissions: {
        insert: false,
        display: false,
        update: false,
        delete: false,
      },
      deoPermissions: {
        insert: false,
        display: false,
        update: false,
        delete: false,
      },
      pendingPermissions: {
        insert: false,
        display: false,
        update: false,
        delete: false,
      },
      rolesPermissions: {
        insert: false,
        display: false,
        update: false,
        delete: false,
      },
    };
  },
  created() {
    this.gettingAllMenus();
  },
  computed: {
    ...mapGetters("roleMenuPermission", ["getMenus"]),
  },
  methods: {
    ...mapActions("roleMenuPermission", ["allMenus", "createRole"]),
    gettingAllMenus() {
      this.allMenus()
        .then((res) => {
          // console.log('res', res)
          this.componentMenus.data = res.data.data;
          let response = res.data.data;
          response.forEach((val) => {
            this.myValue.push([]);
          });
          // console.log('componentMenus:', this.componentMenus)
          // console.log(this.myValue, 'myValue')
          this.$store.commit("roleMenuPermission/setMenus", res.data.data);
          //   console.log(this.componentMenus, 'component menus:');
          //   for(let j=0; j< this.menuPermissions.length; j++){
          //       let ini = 0;
          //       console.log(this.menuPermissions[j].value, 'menu')
          //   }
          for (let i = 0; i < this.componentMenus.data.length; i++) {
            this.componentMenus.data[i].permissions = this.menuPermissions;
          }
          // console.log(this.componentMenus);
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    addRole() {
      // console.log("my value", this.myValue[0]);
      // console.log("Menus", this.getMenus);
      let menuIds = [];
      for (let i = 0; i < this.getMenus.length; i++) {
        menuIds.push(this.getMenus[i].meniuid);
      }
      let obj = [
        {
          menu_id: menuIds[0],
          displaypermission: this.dashboardPermissions.display,
          insertpermission: this.dashboardPermissions.insert,
          updatepermission: this.dashboardPermissions.update,
          deletepermission: this.dashboardPermissions.delete
          // permission: this.myValue[0],
        },
        {
          menu_id: menuIds[1],
          displaypermission: this.deoPermissions.display,
          insertpermission: this.deoPermissions.insert,
          updatepermission: this.deoPermissions.update,
          deletepermission: this.deoPermissions.delete
          // permission: this.myValue[1],
        },
        {
          menu_id: menuIds[2],
          displaypermission: this.pendingPermissions.display,
          insertpermission: this.pendingPermissions.insert,
          updatepermission: this.pendingPermissions.update,
          deletepermission: this.pendingPermissions.delete
          // permission: this.myValue[2],
        },
        {
          menu_id: menuIds[3],
          displaypermission: this.rolesPermissions.display,
          insertpermission: this.rolesPermissions.insert,
          updatepermission: this.rolesPermissions.update,
          deletepermission: this.rolesPermissions.delete
          // permission: this.myValue[3s],
        }
      ];
      console.log('my obj', obj);
      this.createRole({ role_name: this.roleName, menu_permissions: obj })
        .then((res) => {
          // console.log('res', res)
          this.$store.commit(
            "roleMenuPermission/setRoleSnackMessage",
            "Role Created Succesfully"
          );
          this.roleName = "";
          this.$router.push({
            name: "Roles",
          });
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    viewRolesComponent() {
      this.$router.push({
        name: "Roles",
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>