<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn class="mr-5" small rounded @click="activeBranches">show Active </v-btn>
    <v-btn  small rounded @click="inActiveBranches">show InActive </v-btn>
    </div>
      <v-data-table
    :headers="headers"
    :items="branches"
    :items-per-page="10"
    class="elevation-1 mt-3"
  >
  <template v-slot:item.action="{ item }">
      
        <v-btn icon color="primary" @click="viewInvoice(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import {mapActions,mapGetters,mapMutations} from 'vuex'
export default {
    data(){
        return{
            branches:[],
            headers: [
          {
            text: 'Business Name',
            align: 'start',
            sortable: false,
            value: 'businessname',
          },
          {
            text: 'Branches Name',
            align: 'start',
            sortable: false,
            value: 'branchname',
          },
          {text:'Action',value:'action'}
        ],
        }
    },
    created(){
        this.getBranches();
    },
    computed:{
        ...mapGetters("business",["getBusinessId"])
    },
    methods:{
        ...mapActions("branches",["allBranches"]),
        getBranches(){
            this.allBranches(this.getBusinessId).then((res)=>{
                console.log("All Branches",res)
                this.branches=res.data.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        viewInvoice(item){
          this.$emit("wantToShowDialog",true)
          let invoice = item
          console.log("invoice deatails",invoice)
          this.$store.commit('branches/setBranchesId',invoice.branchid)

          // this.$router.push({
          //   name: "Business",
          //   params: { showComponent: "businesInvoice" },
          // });
        },
        activeBranches(){
          this.$router.push({
            name: "Branches",
            params: { showComponent: "activeBranches" },
          });
        },
        inActiveBranches(){
          this.$router.push({
            name: "Branches",
            params: { showComponent: "inActiveBranches" },
          });
        }
    }
}
</script>

<style>

</style>