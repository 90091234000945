<template>
  <div>
    <v-container>
      <v-toolbar elevation="2" class="white" rounded>
        <v-list-item class="pl-0 ">
          <v-list-item-avatar
            color="deep-orange lighten-4"
            v-if="titleChild.image"
            rounded
            size="64"
            class="ml-n4"
          >
            <v-img
              :src="require('@/assets/varificationType/' + titleChild.image)"
              max-width="45"
              min-width="45"
              height="56"
            ></v-img>

            <!-- <v-icon color="white">
                 </v-icon> -->
          </v-list-item-avatar>
          <v-list-item-content>
            <!-- {{this.dataReceived.message.extracted_data.Card_Type}} -->

            <v-list-item-title>Please Verify Data</v-list-item-title>
            <v-list-item-subtitle v-if="titleChild.title">
              {{ titleChild.title }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-toolbar-title> Verify Your Data</v-toolbar-title> -->
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn depressed :color="isAvailable ? 'success' : 'error'">
            {{ isAvailable ? "DEO Is Free" : "DEO is Busy" }}
          </v-btn>
          <v-sheet class="pa-5">
            <!-- <span class="ml-4"> Available</span> -->
            <v-switch
              v-model="isAvailable"
              hide-details
              label="Is Available"
              @change="confirmationDialog"
              style="width:137px"
              inset
            >
            </v-switch>
          </v-sheet>
        </v-toolbar-items>
      </v-toolbar>

      <v-snackbar
        timeout="10000"
        v-model="snack"
        absolute
        bottom
        color="green"
        elevation="24"
      >
        Failed to process customer data
      </v-snackbar>

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <index v-if="showComponent == 'default'"></index>
          <id-card-extracted
            v-if="showComponent == 'idCard'"
            :dataReceived="dataReceived"
            :showComponent="changeCurrentComponent"
            @receivingData="receivingData($event)"
            @titleEmitted="titleReceived($event)"
          ></id-card-extracted>
          <passport-extracted
            v-if="showComponent == 'passport'"
            :showComponent="changeCurrentComponent"
            :dataReceived="dataReceived"
            @receivingData="receivingData($event)"
            @titleEmitted="titleReceived($event)"
          ></passport-extracted>
          <driving-license-extracted
            v-if="showComponent == 'license'"
            :showComponent="changeCurrentComponent"
            :dataReceived="dataReceived"
            @receivingData="receivingData($event)"
            @titleEmitted="titleReceived($event)"
          ></driving-license-extracted>
          <address-extracted
            v-if="showComponent == 'address-extracted'"
            :showComponent="changeCurrentComponent"
            :dataReceived="dataReceived"
            @receivingData="receivingData($event)"
            @titleEmitted="titleReceived($event)"
          ></address-extracted>
          <!-- {{dataReceived.message}} -->
        </v-col>
      </v-row>
      <v-overlay :value="showOverlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="dialog" persistent max-width="490">
        <v-card>
          <v-card-title class="text-h5 justify-center pt-10 d-flex flex-column">
            <v-avatar color="error " size="100" rounded class="mb-8">
              <v-icon size="70" class="white--text"
                >mdi-close-network-outline</v-icon
              ></v-avatar
            >

            Are you sure?
          </v-card-title>
          <v-card-text class="text-center subtitle-1 font-weight-medium mb-0">{{
            dialogMessagePreview
          }}</v-card-text>
          <v-card-actions class="justify-center pb-8">
            <v-hover v-slot="{ hover }">
              <v-btn
                color="white"
                @click="setAgreeOrDisagree(false)"
                min-width="130"
                class="error--text mr-8 text-capitalize"
                large
                :elevation="hover ? 10 : 1"
              >
                <v-icon left>mdi-close-box-outline</v-icon> Disagree
              </v-btn>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-btn
                color="white"
                @click="setAgreeOrDisagree(true)"
                min-width="130"
                large
                :elevation="hover ? 10 : 1"
                class="success--text text-capitalize"
              >
                <v-icon left>mdi-checkbox-marked-circle-outline</v-icon> Agree
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="timeOutDialog" persistent max-width="490">
        <v-card>
          <v-card-title class="text-h5 justify-center pt-10 d-flex flex-column">
            <v-avatar color="error " size="100" rounded class="mb-8">
              <v-icon size="70" class="white--text"
                >mdi-close-network-outline</v-icon
              ></v-avatar
            >

            Taking Longer Than Usual
          </v-card-title>
          <v-card-text class="text-center subtitle-1 font-weight-medium mb-0"
            >Verification Is taking Longer Than Usual.Meanwhile You can check Pending requests  </v-card-text
          >
          <v-card-actions class="justify-center pb-8">
            <v-hover v-slot="{ hover }">
              <v-btn
                color="white"
                @click="timeOutDialog = false"
                min-width="130"
                class="error--text mr-8 text-capitalize"
                large
                :elevation="hover ? 10 : 1"
              >
                <v-icon left>mdi-close-box-outline</v-icon> Close
              </v-btn>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-btn
                color="white"
                :to="{ name: 'PendingVerifications' }"
                min-width="130"
                large
                :elevation="hover ? 10 : 1"
                class="success--text text-capitalize"
              >
                <v-icon left>mdi-checkbox-marked-circle-outline</v-icon> View
                Pending Requests
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import idCardExtracted from "../components/DeoPages/idCardExtracted.vue";
import PassportExtracted from "../components/DeoPages/passportExtracted.vue";
import addressExtracted from "../components/DeoPages/addressExtracted.vue";
import DrivingLicenseExtracted from "../components/DeoPages/drivingLicenseExtracted.vue";
import index from "../components/DeoPages/index.vue";
import { mapActions, mapGetters } from "vuex";
import config from "../config/config.js";

export default {
  data() {
    return {
      timeOutDialog:false,
      showOverlay: false,
      currentStatus: "Deo Is Busy",
      isAvailable: true,
      verificationType: "",
      showComponent: "default",
      dialog: false,
      snack: false,
      status: "disconnect",
      status1: "disconnect1",
      socket: "",
      socket1: "",
      message: "",
      timeout: null,
      timeout1: null,
      dataReceived: null,
      titleChild: {
        title: null,
        image: null,
      },
    };
  },
  watch:{
    showComponent: function(){
              this.resetConnection()
    }
  },
  mounted() {
    this.timeout1 = setInterval(() => {
      this.checkDeoStatusWithOutLoader();
    }, 30000);
    // if(this.dataReceived !== ""){
    //   clearInterval()
    // }
  },
  created() {
    // this.showComponent = "license";
    this.connected();
    // await this.updateDeoStatus({ status: "Free" });
    this.checkDeoStatus();

    // this.updateDeoStatus({status: 'Free'})
    // console.log(this.getDeoId.replace(/-/g, ""), "Id:")
  },
  beforeDestroy() {
    clearInterval(this.timeout1);
    this.resetConnection()
    this.updateDeoStatus({ status: "Online" });
    this.snack = false;
    this.timeout = "";
  },
  components: {
    idCardExtracted,
    PassportExtracted,
    DrivingLicenseExtracted,
    addressExtracted,
    index,
  },
  computed: {
    ...mapGetters("auth", ["getUserId"]),
    dialogMessagePreview() {
      if (this.isAvailable) {
        return "Do you really want to change your status to Free?";
      } else {
        return "Do you really want to change your status to Not Available?";
      }
    },
  },
  methods: {
    ...mapActions("deo", ["updateDeoStatus", "deoCurrentStatus"]),
    async resetConnection(){
              await this.disconnect();
              await this.disconnect1();
              await this.connected();
    },
    confirmationDialog(event) {
      this.dialog = true;
    },
    setAgreeOrDisagree(status) {
      if (!status) {
        this.isAvailable = !this.isAvailable;
      } else {
        if (this.isAvailable) {
          this.updateDeoStatus({ status: "Free" });
        } else {
          this.updateDeoStatus({ status: "Online" });
        }
      }
      this.dialog = false;
    },
    connected() {
      // this.showOverlay = true;
      this.socket = new WebSocket(
        // `${config.seck}/ws/api-deo/${this.getUserId.replace(/-/g, "")}/`
        `${config.seck}/ws/api-deo/${this.getUserId}/`
      );
      this.socket.onopen = () => {
        // this.showOverlay = false;
        this.status = "connected";
        this.showOverlay = false;
      };
      this.socket.onmessage = async (data) => {
        this.status = "connected";
        this.isAvailable = false;
        this.showOverlay = true;
        this.dataReceived = JSON.parse(data.data);
        // console.log(this.dataReceived, "data 1")
        // this.showOverlay = true;
          if (this.dataReceived.content_type == "extracted_data") {
          // alert("extracted data");
          this.showOverlay = false;
          this.changeComponent();
        } else if (this.dataReceived.content_type == "status_busy") {
          // alert('status_busy')
          // console.log(this.dataReceived.message, "channel received:");
          // var audio = new Audio("https://kycadmin.devco.pk/kyc-ring.mp3");

          // audio.play();
          this.socket1 = new WebSocket(
          //   `${config.seck}/ws/api-deo/${this.dataReceived.message.replace(
          //     /-/g,
          //     ""
          //   )}/`
          // );
          `${config.seck}/ws/api-deo/${this.dataReceived.message}/`
          );
          this.socket1.onopen = () => {
            this.status = "connected1";
            // alert('connected1')
            this.timeout = setTimeout(() => {
              
              this.isAvailable = true;
              this.showOverlay = false;
              this.timeOutDialog=true
              this.updateDeoStatus({ status: "Free" });
              this.resetConnection()
            }, 60000);
          };
          // Failed to proecess API
          this.socket1.onmessage = async (data) => {
            this.showOverlay = false;
            clearTimeout(this.timeout);
            this.dataReceived = JSON.parse(data.data);
            // alert('onmessage')
            if (this.dataReceived.message == "Failed") {
              this.isAvailable = true;
              await this.updateDeoStatus({ status: "Free" });
              await this.checkDeoStatus();
              this.snack = true;
            } else {
              this.showOverlay = false;
              this.changeComponent();
            }
          };
        }
        // this.messageReceived = data.data.message;
        console.log(this.status);
      };
    },
    titleReceived(titleEmitted) {
      // console.log('title Emittted', titleEmitted)
      this.titleChild.title = titleEmitted.title;
      this.titleChild.image = titleEmitted.svg;
    },
    receivingData(urlResult) {
      console.log(urlResult, "resultURL:");
      this.socket.send(
        JSON.stringify({
          message: urlResult,
          content_type: "newData",
        })
      );
      this.updateDeoStatus({ status: "Free" });
      this.checkDeoStatus();
      // await this.disconnect1();
      this.connected();
    },
    disconnect() {
      this.socket.close();
      this.status = "disconnected";
      console.log(this.status);
    },
    disconnect1() {
      this.socket1.close();
      this.status1 = "disconnected1";
      console.log(this.status1);
    },
    deoStatus(status) {
      // alert(status);
      // this.sendMSG()
      this.titleChild.title = "";
      this.titleChild.image = "";
      this.isAvailable = status;
      if (status) {
        this.currentStatus = "Deo Is Free";
        this.updateDeoStatus({ status: "Free" });
      } else {
        this.currentStatus = "Not Available";
        this.updateDeoStatus({ status: "Online" });
        this.showComponent = "default";
      }
      this.dialog = false;
    },
    checkDeoStatus() {
      this.showOverlay = true;
      this.deoCurrentStatus().then(async (res) => {
        if ((await res.data.success.toLowerCase()) == "ok") {
          this.showOverlay = false;
          // console.log(res, "current status::")
          // this.currentStatus = res.data.deo_status;
          if (res.data.deo_status == "Free") {
            // console.log('fa')
            this.isAvailable = true;
            this.currentStatus = "Deo Is Free";
          } else if (res.data.deo_status == "Not Available") {
            // console.log('na')
            this.isAvailable = false;
            this.currentStatus = "Not Available";
          } else {
            this.currentStatus = "Deo Is Busy";
            this.isAvailable = false;
          }
        }
        // console.log("checkDEOStatus::", res.data);
      });
    },
    checkDeoStatusWithOutLoader() {
      // this.showOverlay = true;
      this.deoCurrentStatus().then(async (res) => {
        if ((await res.data.success.toLowerCase()) == "ok") {
          // this.showOverlay = false;
          // console.log(res, "current status::")
          // this.currentStatus = res.data.deo_status;
          if (res.data.deo_status == "Free") {
            // console.log('fa')
            this.isAvailable = true;
            this.currentStatus = "Deo Is Free";
          } else if (res.data.deo_status == "Not Available") {
            // console.log('na')
            this.isAvailable = false;
            this.currentStatus = "Not Available";
          } else {
            this.currentStatus = "Deo Is Busy";
            this.isAvailable = false;
          }
        }
        // console.log("checkDEOStatus::", res.data);
      });
    },
    changeComponent() {
      console.log(this.dataReceived, "Data Received:");
      if (this.dataReceived.message.extracted_data.Card_Type == "ID Card") {
        this.showComponent = "idCard";
      } else if (
        this.dataReceived.message.extracted_data.Card_Type == "Passport"
      ) {
        this.showComponent = "passport";
      } else if (
        this.dataReceived.message.extracted_data.Card_Type == "address"
      ) {
        this.showComponent = "address-extracted";
      } else {
        this.showComponent = "license";
      }
    },
    changeCurrentComponent(componentName) {
      if (componentName == "default") {
        this.resetConnection()
        this.currentStatus = "Deo Is Free";
        this.showComponent = "default";
        // this.checkDEOStatus();
      }
      this.showComponent = componentName;
    },
  },
};
</script>

<style lang="scss" scoped></style>
