<template>
    <v-container>
            <view-all v-if="!showComponent" @menuDetails="onMenuClick"></view-all>
            <create v-else-if="showComponent == 'create'"></create>
            <edit :menuDetail="menuDetail" v-else-if="showComponent == 'edit'"></edit>
            <view-single v-else-if="showComponent == 'view'"></view-single>
    </v-container>
</template>

<script>
import viewAll from "../../components/Menus/viewAll.vue"
import edit from "../../components/Menus/edit.vue"
import create from "../../components/Menus/create.vue"
import viewSingle from "../../components/Menus/viewSingle.vue"
    export default {
        props: ["showComponent"],
        data(){
            return {
                menuDetail: null,
            }
        },
        components: { viewAll, edit, create, viewSingle },
        methods: {
            onMenuClick(value){
                // console.log(value,'value')
                this.menuDetail = value;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>