<template>
  <div>
    <v-navigation-drawer width="380" clipped left app v-model="leftDrawer"> 
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="subtitle-2">
            OCR Extracted Details
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-tabs 
      fixed-tabs v-model="tab" 
      active-class="deep-orange darken-2 white--text" 
      next-icon="mdi-arrow-right-bold-box-outline"
       prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows>
        <v-tab class="text-capitalize">
          <v-icon left>mdi-camera-outline</v-icon> Images Details
        </v-tab>
        <v-tab class="text-capitalize">
          <v-icon left>mdi-video-outline</v-icon> Video Details
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <v-card-text class="pa-2">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-hover v-slot="{ hover }" open-delay="200">
                    <v-card
                      :elevation="hover ? 4 : 1"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-card-text class="pa-3">
                        <v-subheader
                          class="subtitle-2 font-weight-medium pa-0 mb-2"
                          style="height:18px"
                        >
                          Front Card Image</v-subheader
                        >
                        <inner-image-zoom
                          :src="form.frontImage"
                          :zoomSrc="form.frontImage"
                        />
                        <!-- <image-zoom style="width:100%;"
                :click-zoom="true"
                  :zoom-amount="2"
                  img-class="img-fluid"
                  :regular="form.frontImage"
                  :zoom="form.frontImage">				
              </image-zoom> -->
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-hover v-slot="{ hover }" open-delay="200">
                    <v-card
                      :elevation="hover ? 4 : 1"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-card-text class="pa-3">
                        <v-subheader
                          class="subtitle-2 font-weight-medium pa-0 mb-2"
                          style="height:18px"
                        >
                          Back Card Image</v-subheader
                        >

                        <inner-image-zoom
                          :src="form.backImage"
                          :zoomSrc="form.backImage"
                        />
                        <!-- <image-zoom style="width:100%;"
                  :click-zoom="true"
                  :zoom-amount="2"
                  img-class="img-fluid"
                  :zoom="form.backImage"
                :regular="form.backImage">				
              </image-zoom> -->
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
            <v-card>
            <v-card-text class="pa-2">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-hover v-slot="{ hover }">
                <v-card rounded :elevation="hover ? 6 : 2">
                   <v-subheader class="subtitle-2 font-weight-medium py-4 mb-0"
                          style="height:18px"
                      >ID Card Front Image <v-spacer></v-spacer>
                    </v-subheader>
                    <v-divider></v-divider>
                  <v-card-text>
                    
                    
                  <div class="text-center">
                    <!-- <img
                        :src="`${form.frontCardImage}`"
                        alt=""
                        width="100%"
                        height="260px"
                        class="extracted-img"
                      /> -->
                    <video
                      width="100%"
                      height="200px"
                      class="extracted-img"
                      controls
                      loop
                    >
                      <source
                        :src="`${form.frontCardImage}`"
                        type="video/mp4"
                      />
                      <source
                        :src="`${form.frontCardImage}`"
                        type="video/webm"
                      />
                      <source
                        :src="`${form.frontCardImage}`"
                        type="video/ogg"
                      />
                      Video tag is not supported in this browser.
                    </video>
                  </div>
                   </v-card-text>
                </v-card>
              </v-hover>
                </v-col>
                 <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-hover v-slot="{ hover }">
                <v-card rounded :elevation="hover ? 6 : 2">
                   <v-subheader class="subtitle-2 font-weight-medium py-4 mb-0"
                          style="height:18px"
                      >ID Card Back Image<v-spacer></v-spacer>
                    </v-subheader>
                    <v-divider></v-divider>
                  <v-card-text>
                  <div class="text-center">
                    <!-- <img
                        :src="`${form.backCardImage}`"
                        width="100%"
                        height="260px"
                        class="extracted-img"
                      /> -->
                    <video
                      width="100%"
                      height="200px"
                      class="extracted-img"
                      controls
                      loop
                    >
                      <source :src="`${form.backCardImage}`" type="video/mp4" />
                      <source
                        :src="`${form.backCardImage}`"
                        type="video/webm"
                      />
                      <source :src="`${form.backCardImage}`" type="video/ogg" />
                      Video tag is not supported in this browser.
                    </video>
                  </div>
                  </v-card-text>
                </v-card>
              </v-hover>

              <!-- <button id='back' @click="pauseBackImage">Pause</button> -->
                 </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-hover v-slot="{ hover }">
                <v-card rounded :elevation="hover ? 6 : 2">
                    <v-subheader class="subtitle-2 font-weight-medium py-4 mb-0"
                          style="height:18px"
                      >Selfie/Video<v-spacer></v-spacer>
                    </v-subheader>
                    <v-divider></v-divider>
                  <v-card-text>
                  <div class="text-center">
                    <!-- <img
                        :src="`${form.faceImage}`"
                        width="100%"
                        height="260px"
                        class="extracted-img"
                      /> -->
                    <video
                      width="100%"
                      height="200px"
                      class="extracted-img"
                      controls
                      loop
                    >
                      <source :src="`${form.faceImage}`" type="video/mp4" />
                      <source :src="`${form.faceImage}`" type="video/webm" />
                      <source :src="`${form.faceImage}`" type="video/ogg" />
                      Video tag is not supported in this browser.
                    </video>
                  </div>
                  </v-card-text>
               
                </v-card>
              </v-hover>

              <!-- <button id='face' @click="pauseFaceImage">Pause</button> -->
            </v-col>
              </v-row>
            </v-card-text>
            </v-card>
        
        </v-tab-item>
      </v-tabs-items>
    </v-navigation-drawer>
    
    <v-navigation-drawer width="290" clipped right app>
      <template v-slot:prepend>
        <v-subheader class="subtitle-1 font-weight-medium">User Detail</v-subheader>
      </template>

      <v-divider></v-divider>
      <v-list flat>
        <v-subheader class="subtitle-2 font-weight-medium">Main Detail </v-subheader>
        <v-list-item-group v-model="settings" multiple>
          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.data.message.checks.name"
                  :input-value="dataReceived.data.message.checks.name"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Full Name</v-list-item-title>
                <v-list-item-subtitle>{{
                  dataReceived.data.message.full_name
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                   v-model="dataReceived.data.message.checks.dob"
                  :input-value="dataReceived.data.message.checks.dob"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Date of Birth</v-list-item-title>
                <v-list-item-subtitle>{{
                  dataReceived.data.message.dob
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.data.message.checks.expiry"
                  :input-value="dataReceived.data.message.checks.expiry"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Card Expiry Date</v-list-item-title>
                <v-list-item-subtitle>{{
                  dataReceived.data.message.expiry_date
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>

        <v-subheader class="subtitle-2 font-weight-medium">Compared Detail</v-subheader>

        <v-list-item-group v-model="settings" multiple>
          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.data.message.checks.face"
                  :input-value="dataReceived.data.message.checks.face"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Face Image</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.data.message.checks.face_compare"
                  :input-value="dataReceived.data.message.checks.face_compare"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Face Compare</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.data.message.checks.country_compare"
                  :input-value="
                    dataReceived.data.message.checks.country_compare
                  "
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Country Compare</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="dataReceived.data.message.checks.document_image"
                  :input-value="dataReceived.data.message.checks.document_image"
                  color="primary"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Document Image</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

        
        </v-list-item-group>
      </v-list>
      <v-subheader class="subtitle-2 font-weight-medium">Rejection Remarks</v-subheader>
      <div class="pa-4">
        <v-select
        v-model="form.remarks"
        :items="rejectItems"
        filled
        label="Rejection Remarks"
        multiple
     
      >
        <template v-slot:prepend-item>
          <v-card flat>
            <v-card-text class="pa-2">
                <v-text-field
                  label="Other Reason"
                  v-model="form.remarks"
                  filled
                  hide-details="auto"
                  single-line
                ></v-text-field>
            </v-card-text>
          </v-card>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
       </div>
       <template v-slot:append>
        <div class="px-5 py-3 grey lighten-4">
           <v-btn
          class="text-capitalize primary"
          color="primary"
          :disabled="!isAllowedToVerify ||
              selectedKYBMembers.length > 0 ||
              selectedSanctionMembers.length > 0 ||
              selectedAMLMembers.length > 0
          "
          @click="sendVerifiedData(['verified'])"
          ><v-icon left>mdi-check-decagram</v-icon>  Verify</v-btn
        >
        <v-btn
          class="text-capitalize mr-0 float-right"
          color="error"
          :disabled="form.remarks == '' && (!selectedKYBMembers.length && !selectedSanctionMembers.length && !selectedPepMembers.length && !selectedAMLMembers.length)"
          @click="sendVerifiedData(form.remarks)"
          >Reject</v-btn
        >
        </div>
       </template>
    
    </v-navigation-drawer>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card>
            <v-card-title class="pt-0 pb-0 subtitle-1 font-weight-medium">
              Extracted Details
              <v-spacer></v-spacer>
              <v-subheader class="subtitle-2 font-weight-medium "
                >Face Comparison</v-subheader
              >
              <v-chip
                small
                :color="form.verificationStatus ? 'green' : 'red'"
                class="white--text ml-0"
                >{{
                  form.verificationStatus ? "Verified" : "Unverified"
                }}</v-chip
              >
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    label="Person Name"
                    dense
                    v-model="form.personName"
                    filled
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    label="Father Name"
                    dense
                    filled
                    v-model="form.fatherName"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-text-field
                    label="CNIC"
                    dense
                    filled
                    v-model="form.CNIC"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    label="Date of Birth"
                    dense
                    filled
                    v-model="form.DOB"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    label="Card Registration Date"
                    dense
                    filled
                    v-model="form.cardRegisterationDate"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    label="Card Expiry Date"
                    dense
                    filled
                    v-model="form.cardExpiryDate"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    label="Gender"
                    dense
                    filled
                    v-model="form.gender"
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Model Distance"
                      dense
                      filled
                      v-model="form.distance"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Maximum Threshold"
                      dense
                      filled
                      v-model="form.maxThreshhold"
                      hide-details
                    ></v-text-field>
                  </v-col> -->
                <!-- <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                      filled
                      label="DEO Remarks"
                      v-model="form.remarks"
                      rows="2"
                      dense
                      hide-details
                    ></v-textarea>
                    <v-select
                      v-model="form.remarks"
                      :items="rejectItems"
                      attach
                      chips
                      label="Rejection Remarks"
                      multiple
                    >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-text-field label="Other Reason" v-model="form.remarks"></v-text-field> 
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    </v-select>
                  </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card>
             <v-tabs fixed-tabs v-model="tabTwo" 
             active-class="deep-orange darken-2 white--text"  
             next-icon="mdi-arrow-right-bold-box-outline"
             prev-icon="mdi-arrow-left-bold-box-outline"
             show-arrows>
            <v-tab class="text-capitalize">
            <v-icon left>mdi-google-maps</v-icon>   Other Information
            </v-tab>
            <v-tab class="text-capitalize">
           <v-icon left>mdi-globe-model</v-icon>   System Info
            </v-tab>
            <v-tab class="text-capitalize">
            <v-icon left>mdi-briefcase-account-outline</v-icon>     KYB
            </v-tab>
            <v-tab class="text-capitalize">
           <v-icon left>mdi-account-cash</v-icon>     AML
            </v-tab>
            </v-tabs>
          <v-tabs-items v-model="tabTwo">
            <v-tab-item>
              <v-row >
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-card>
                    <!-- <v-card-title>
                      <v-subheader class="title py-0">
                        Other Information
                      </v-subheader>
                    </v-card-title> -->
                    <v-card-text >
                      <v-subheader class="subtitle-1 font-weight-medium">Geo Location</v-subheader>
                      <v-divider></v-divider>
                      <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>ip</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.ip
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>ISO Code</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.ip
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Country</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.country_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <!--  -->
                     <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title
                              >State/Province</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.sub_div_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>District</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.district_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Postal Code</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.postal_code
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>

                      <!--  -->
                     <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Capital</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.country_capital
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Latitude</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.latitude
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Longitude</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.longitude
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>

                     <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Time zone</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.time_zone
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title
                              >Country curret time</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc
                                .country_curret_time
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Continent</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.continent
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>

                     <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>gmt offset</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.gmt_offset
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>calling code</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.calling_code
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title
                              >top level domain</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.top_level_domain
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>

                      <!--  -->
                     <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Languages</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.languages
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>country flag</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.country_flag
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title
                              >continent code</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.continent_code
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>

                     <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>currency</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.currency
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>City</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.city_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title
                              >continent code</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.geo_loc.continent_code
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-card>
                    <!-- <v-card-title></v-card-title> -->
                    <v-card-text>
                          <v-subheader class="subtitle-1 font-weight-medium">System Info</v-subheader>
                          <v-divider></v-divider>
                     <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Browser</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.system_info.browser
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title
                              >Operating System</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.system_info.deviceType
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Device type</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.system_info.deviceType
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>

                     <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Brand</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.system_info.brand
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>ASN number</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.asn_data.asn_number
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>ASN name</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.asn_data.asn_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>

                      <!--  -->
                      <div class="d-flex grey lighten-5" >
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>Network</v-list-item-title>
                            <v-list-item-subtitle>{{
                              dataReceived.data.message.asn_data.network
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-card>
                    <v-card-text>
                      
                    <v-subheader class="subtitle-1 font-weight-medium">
                        Know Your Business
                      </v-subheader>
                       <v-divider></v-divider>
                  <v-data-table
                    v-model="selectedKYBMembers"
                    :headers="headers"
                    :items="dataReceived.data.message.kyb_data"
                    :single-select="singleSelect"
                    item-key="id"
                    show-select
                    class="elevation-0"
                  >
                    
                    <template v-slot:item.action="{ item }">
                      <v-btn icon @click="viewDetail(item)"
                        ><v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                   </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-card>
                    <v-card-text>
                      
                    <v-subheader class="subtitle-1 font-weight-medium">
                      </v-subheader>
                      <v-divider></v-divider>
                  <v-data-table
                    :headers="aml_headers"
                    :items="aml_data"
                    class="elevation-0"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    item-key="name"
                    show-expand
                    hide-default-footer
                    @item-expanded="getIndividualAmlDetail"
                  >
                   

                    <template v-slot:item.name="{ item, index }">
                      <span class="ml-2" v-if="index == 0"> Sanction </span>
                      <span class="ml-2" v-if="index == 1"> PEP </span>
                      <span class="ml-2" v-if="index == 2"> AML </span>
                    </template>

                    <template v-slot:item.quantity="{ item, index }">
                      <span class="ml-2" v-if="index == 0">
                        {{ item.value.length }}
                      </span>
                      <span class="ml-2" v-if="index == 1">
                        {{ item.value.length }}
                      </span>
                      <span class="ml-2" v-if="index == 2">
                        {{ item.value.length }}
                      </span>
                    </template>

                    <!--  -->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length" class="pa-0">
                        <v-card tile elevation="0">
                          <v-card-text class="px-0 py-0 my-0">
                            <!-- sanction data table -->
                            <v-data-table
                              v-show="showExpendedItem == 'sanction'"
                              v-model="selectedSanctionMembers"
                              :headers="sanctionHeaders"
                              :items="aml_data[0].value"
                              item-key="_id"
                              show-select
                              :single-select="singleSelect"
                              class="elevation-0 px-0 mx-0"
                            >
                              <template v-slot:item.action="{ item }">
                                <v-btn @click="viewSanctionDetail(item)" icon
                                  ><v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                            </v-data-table>

                            <!-- pep data table -->
                            <v-data-table
                              v-show="showExpendedItem == 'pep'"
                              v-model="selectedPepMembers"
                              :headers="pepHeaders"
                              :items="aml_data[1].value"
                              :items-per-page="-1"
                               show-select
                               item-key="_id"
                               :single-select="singleSelect"
                              class="elevation-0 px-0 mx-0"
                            >
                              <template v-slot:item.action="{ item }">
                                <v-btn @click="viewPepDetail(item)" icon
                                  ><v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                            </v-data-table>

                             <!-- AML data table -->
                            <v-data-table
                              v-show="showExpendedItem == 'aml'"
                              v-model="selectedAMLMembers"
                              :headers="amlHeaders"
                              :items="aml_data[2].value"
                              :items-per-page="-1"
                              show-select
                              item-key="_id"
                              :single-select="singleSelect"
                              class="elevation-0 px-0 mx-0"
                            >
                              <template v-slot:item.action="{ item }">
                                <v-btn @click="viewAMLDetail(item)" icon
                                  ><v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </td>
                    </template>
                  </v-data-table>
                    </v-card-text>
                    </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
            </v-card>
        </v-col>
         </v-row>

      <!-- KBY Detail Dialog -->
      <!-- KBY Detail Dialog -->
  <v-dialog v-model="viewDetailDialog"  fullscreen>
    <v-card tile>
       <v-toolbar tile class=" elevation-2" fixed  color="white"  height="60">
      <v-toolbar-title>
        <v-list-item class="ml-0 pl-0">
          
          <v-list-item-content>
            <v-list-item-title
              class="subtitle-1 font-weight-medium"
            >
              Detail
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
             <span>Know Your Business</span>
              
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    
 <v-btn icon class="ma-0" color="error"  @click="viewDetailDialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >

  
       </v-toolbar>
     
      <v-divider></v-divider>
      <v-container fluid>
        
     
        <v-row  class="mt-0">
           <v-col cols="12" sm="12" md="3" lg="3" xl="3">
             <v-list rounded dense class="pa-0 ma-0">
              <v-list-item class="grey lighten-4">
            <v-list-item-content>
              <v-list-item-title>Name</v-list-item-title>
              <v-list-item-subtitle>{{
                kyb_detail && kyb_detail.name
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
             </v-list>
           </v-col>
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-list rounded dense class="pa-0 ma-0">
                 <v-list-item class="grey lighten-4">
            <v-list-item-content>
              <v-list-item-title>Date of Birth</v-list-item-title>
              <v-list-item-subtitle>{{
                kyb_detail && kyb_detail.date_of_birth
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
              </v-list>
           </v-col>
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
               <v-list dense rounded class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
            <v-list-item-content>
              <v-list-item-title>Nationality</v-list-item-title>
              <v-list-item-subtitle
                v-for="(item, index) in kyb_detail &&
                  kyb_detail.nationality"
                :key="index"
                >   
                  <v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <span
      v-bind="attrs"
      v-on="on"
    >{{ item }}</span>
  </template>
  <span>{{ item }}</span>
</v-tooltip>
                
                 </v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
               </v-list>
           </v-col>
            <v-col cols="12" sm="12" md="5" lg="5" xl="5">
               <v-list dense rounded class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
            <v-list-item-content>
              <v-list-item-title>Address</v-list-item-title>
              <v-list-item-subtitle
                v-for="(item, index) in kyb_detail && kyb_detail.addresses"
                :key="index"
              >
                    <v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <span
      v-bind="attrs"
      v-on="on"
    >{{ item }}</span>
  </template>
  <span>{{ item }}</span>
</v-tooltip></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
               </v-list>
           </v-col>
        </v-row>
         </v-container>
     <v-container fluid>
        <!--  -->
        <!-- <v-divider class="mt-6"></v-divider> -->
        <v-subheader class="subtitle-1 font-weight-bold">Sources we find</v-subheader>
        <!-- <v-divider class="mb-4"></v-divider> -->
        
        <v-data-iterator
          :items="
            kyb_detail && kyb_detail.sources ? kyb_detail.sources : []
          "
          item-key="id"
        >
          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12" 
                lg="12"
                xl="12"
                v-for="(item, index) in items"
                :key="item.id"
              >
                <!--  -->
                <v-hover
                  v-slot="{ hover }"
                  open-delay="30"
                  close-delay="30"
                >
                
               
                  <v-card :elevation="hover ? 4 : 2">
                    <v-card-text class="pa-1">
<v-card-title class="subtitle-1 font-weight-medium pt-2 pb-4">
   Dataset Name
  <v-spacer></v-spacer>
  <v-chip label  color="primary" >{{index + 1}}</v-chip>
</v-card-title>

<v-card-subtitle class="py-0"> 
  {{item.dataset_name}}
</v-card-subtitle>
<v-divider class="grey lighten-3 mt-3 ml-4 mr-4"></v-divider>
  <v-card-title class="subtitle-1 pt-3 font-weight-medium pb-4">Compnay</v-card-title>
    <v-card-subtitle class="py-0 mb-4"> 
      {{item.id}}
    </v-card-subtitle>
     </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-container>
       
        </v-card>
      </v-dialog>

      <!-- AML Sanction Detail Dialog -->
      <v-dialog v-model="almSanctionDetailDialog" persistent max-width="1200">
        <v-card>
          <v-card-title class="text-h5">
            Detail
          </v-card-title>
          <v-card-text>
            <div class="d-flex">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Name</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlSanctionDetail && amlSanctionDetail.Name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>DOB</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlSanctionDetail && amlSanctionDetail["Date of Birth"]
                      ? amlSanctionDetail && amlSanctionDetail["Date of Birth"]
                      : "N/A"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!--  -->
            <div class="d-flex">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Country</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlSanctionDetail && amlSanctionDetail.Country
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Place of Birth</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlSanctionDetail && amlSanctionDetail["Place of Birth"]
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!--  -->
            <div class="d-flex">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Nationality</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlSanctionDetail && amlSanctionDetail.Nationality
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Remarks</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlSanctionDetail && amlSanctionDetail.Remarks
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!--  -->
            <div class="d-flex">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Identity Number</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlSanctionDetail && amlSanctionDetail["Identity Number"]
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Crime</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlSanctionDetail && amlSanctionDetail.Crime
                      ? amlSanctionDetail && amlSanctionDetail.Crime
                      : "N/A"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!--  -->
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>Address</v-list-item-title>
                <v-list-item-subtitle
                  v-for="(item, index) in amlSanctionDetail &&
                  amlSanctionDetail.Addresses
                    ? amlSanctionDetail.Addresses
                    : []"
                  :key="index"
                  ><v-icon small> mdi-arrow-right-bold-circle-outline </v-icon>
                  {{ item }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <!--  -->
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>Url</v-list-item-title>
                <v-list-item-subtitle>{{
                  amlSanctionDetail && amlSanctionDetail.Url
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error darken-1"
              text
              @click="almSanctionDetailDialog = false"
            >
              CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- AML PEP Detail Dialog -->
      <v-dialog v-model="almPepDetailDialog" persistent max-width="1200">
        <v-card>
          <v-card-title class="text-h5">
            Detail
          </v-card-title>
          <v-card-text>
            <div class="d-flex">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Name</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlPepDetail && amlPepDetail.Name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>DOB</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlPepDetail && amlPepDetail["Date of Birth"]
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!--  -->
            <div class="d-flex">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Country</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlPepDetail && amlPepDetail.Country
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Gender</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlPepDetail && amlPepDetail.Gender
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!--  -->
            <div class="d-flex">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Chamber</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlPepDetail && amlPepDetail.Chamber
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Party</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlPepDetail && amlPepDetail.Party
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!--  -->
            <div class="d-flex">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Area</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlPepDetail && amlPepDetail.Area
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>Image Url</v-list-item-title>
                  <v-list-item-subtitle>{{
                    amlPepDetail && amlPepDetail["Image Url"]
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>

            <!--  -->
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>Social</v-list-item-title>
                <v-list-item-subtitle
                  ><v-icon small>mdi-email-outline</v-icon>
                  {{
                    amlPepDetail && amlPepDetail.Social && amlPepDetail.Social.email
                      ? amlPepDetail.Social.email
                      : "N/A"
                  }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  ><v-icon small>mdi-facebook-box</v-icon>
                  {{
                    amlPepDetail && amlPepDetail.Social && amlPepDetail.Social.twitter
                      ? amlPepDetail.Social.twitter
                      : "N/A"
                  }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  ><v-icon small>mdi-facebook-box</v-icon>
                  {{
                    amlPepDetail && amlPepDetail.Social && amlPepDetail.Social.facebook
                      ? amlPepDetail.Social.facebook
                      : "N/A"
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error darken-1"
              text
              @click="almPepDetailDialog = false"
            >
              CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-overlay :value="showOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

     <!-- AML AML Detail Dialog -->
  <v-dialog v-model="amlDetailDialog"  fullscreen>
    <v-card tile>
       <v-toolbar tile class=" elevation-2" fixed  color="white"  height="60">
      <v-toolbar-title>
        <v-list-item class="ml-0 pl-0">
          
          <v-list-item-content>
            <v-list-item-title
              class="subtitle-1 font-weight-medium"
            >
              Detail
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
             <span>Know Your Business</span>
              
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    
 <v-btn icon class="ma-0" color="error"  @click="amlDetailDialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
       </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid>
        
     
        <v-row  class="mt-0">
           <v-col cols="12" sm="12" md="3" lg="3" xl="3">
             <v-list rounded dense class="pa-0 ma-0">
              <v-list-item class="grey lighten-4">
            <v-list-item-content>
              <v-list-item-title>Name</v-list-item-title>
              <v-list-item-subtitle>{{
                amlDetailData && amlDetailData.Name
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
             </v-list>
           </v-col> 
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-list rounded dense class="pa-0 ma-0">
                 <v-list-item class="grey lighten-4">
            <v-list-item-content>
              <v-list-item-title>Country</v-list-item-title>
              <v-list-item-subtitle>
                {{ amlDetailData && amlDetailData.Country }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
              </v-list>
           </v-col>

            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-list rounded dense class="pa-0 ma-0">
                 <v-list-item class="grey lighten-4">
            <v-list-item-content>
              <v-list-item-title>Source</v-list-item-title>
              <v-list-item-subtitle>
                {{ amlDetailData && amlDetailData.Source }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
              </v-list>
           </v-col>

            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
               <v-list dense rounded class="pa-0 ma-0">
                <v-list-item class="grey lighten-4">
            <v-list-item-content>
              <v-list-item-title>Address</v-list-item-title>
              <v-list-item-subtitle
                v-for="(item,index) in amlDetailData && amlDetailData.Addresses"
                :key="index"
                >   
                  <v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <span
      v-bind="attrs"
      v-on="on"
    >{{ item }}</span>
  </template>
  <span>{{ item }}</span>
</v-tooltip>
                
                 </v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
               </v-list>
           </v-col>
        </v-row>
         </v-container>
     <v-container fluid>
        <!--  -->
        <!-- <v-divider class="mt-6"></v-divider> -->
        <v-subheader class="subtitle-1 font-weight-bold">Entities</v-subheader>
        <!-- <v-divider class="mb-4"></v-divider> -->
        
        <v-data-iterator
          :items="
            amlDetailData && amlDetailData.Entities.length ? amlDetailData.Entities : []
          "
          item-key="id"
        >
          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12" 
                lg="12"
                xl="12"
                v-for="(item, index) in items"
                :key="item.id"
              >
                <!--  -->
                <v-hover
                  v-slot="{ hover }"
                  open-delay="30"
                  close-delay="30"
                >
            <v-card :elevation="hover ? 4 : 2">
                <v-card-text class="pa-1">
<v-card-title class="subtitle-1 font-weight-medium pt-2 pb-4">
   Name
  <v-spacer></v-spacer>
  <v-chip label  color="primary" >{{index + 1}}</v-chip>
</v-card-title>
<v-card-subtitle class="py-0"> 
  {{item.Name}}
</v-card-subtitle>
<v-divider class="grey lighten-3 mt-3 ml-4 mr-4"></v-divider>
  <v-card-title class="subtitle-1 pt-3 font-weight-medium pb-4">Role</v-card-title>
    <v-card-subtitle class="py-0 mb-4"> 
      {{item.Role}}
    </v-card-subtitle>

  <v-divider class="grey lighten-3 mt-3 ml-4 mr-4"></v-divider>
  <v-card-title class="subtitle-1 pt-3 font-weight-medium pb-4">Start Date</v-card-title>
    <v-card-subtitle class="py-0 mb-4"> 
      {{item.Start_date}}
  </v-card-subtitle>

   <v-divider class="grey lighten-3 mt-3 ml-4 mr-4"></v-divider>
  <v-card-title class="subtitle-1 pt-3 font-weight-medium pb-4">End Date</v-card-title>
    <v-card-subtitle class="py-0 mb-4"> 
      {{item.End_Date}}
  </v-card-subtitle>

  <v-divider class="grey lighten-3 mt-3 ml-4 mr-4"></v-divider>
  <v-card-title class="subtitle-1 pt-3 font-weight-medium pb-4">Jurisdiction</v-card-title>
    <v-card-subtitle class="py-0 mb-4"> 
      {{item.Jurisdiction}}
  </v-card-subtitle>

  <v-divider class="grey lighten-3 mt-3 ml-4 mr-4"></v-divider>
  <v-card-title class="subtitle-1 pt-3 font-weight-medium pb-4">Status</v-card-title>
    <v-card-subtitle class="py-0 mb-4"> 
      {{item.Status}}
  </v-card-subtitle>

  <v-divider class="grey lighten-3 mt-3 ml-4 mr-4"></v-divider>
  <v-card-title class="subtitle-1 pt-3 font-weight-medium pb-4">Data Source</v-card-title>
    <v-card-subtitle class="py-0 mb-4"> 
      {{item.Data_Source}}
  </v-card-subtitle>
    
     </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-container>
       
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
export default {
  props: ["pendingVerificationId"],
  components: {
    "inner-image-zoom": InnerImageZoom,
  },
  data() {
    return {
      tab3:null,
      leftDrawer:true,
      tabTwo: null,
      tab: null,
      drawer: true,
      expanded: [],
      selectedKYBMembers:[],
      selectedPepMembers:[],
      selectedAMLMembers:[],
      selectedSanctionMembers:[],
      singleExpand: true,
      viewDetailDialog: false,
      amlDetailDialog:false,
      amlDetailData:null,
      settings: [],
      showExpendedItem: "",
      aml_data: [],
      almSanctionDetailDialog: false,
      almPepDetailDialog: false,
      amlSanctionDetail: null,
      amlPepDetail: null,
      kyb_detail: null,
      aml_headers: [
        { text: "Name", value: "name" },
        { text: "Quantity", value: "quantity" },
      ],
      headers: [
        { text: "Name", value: "name" },
        { text: "DOB", value: "date_of_birth" },
        { text: "View Detail", value: "action" },
      ],
      amlHeaders:[
        { text: "Name", value: "Name" },
        { text: "Country", value: "Country" },
        { text: "View Detail", value: "action" },
      ],
      sanctionHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Date of Birth", value: "dob" },
        { text: "Country", value: "Country" },
        { text: "Place of Birth", value: "place_of_birth" },
        { text: "View Detail", value: "action" },
      ],
      pepHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Date of Birth", value: "dob" },
        { text: "Country", value: "Country" },
        { text: "Gender", value: "Gender" },
        { text: "View Detail", value: "action" },
      ],
      showOverlay: true,
      button1: "Pause",
      button2: "Pause",
      button3: "Pause",
      form: {
        frontCardImage: "",
        backCardImage: "",
        frontImage: "",
        backImage: "",
        faceImage: "",
        personName: "",
        fatherName: "",
        CNIC: "",
        DOB: "",
        cardRegisterationDate: "",
        cardExpiryDate: "",
        gender: "",
        cardType: "",
        imageName: "",
        remarks: "",
        verificationStatus: "",
        distance: "",
        maxThreshhold: "",
      },
      dataReceived: null,
      rejectItems: [
        "Face is different",
        "Image is Blur",
        "Not Similar",
        "Rejected",
        "No Data Extracted",
        "Other",
      ],
    };
  },
  async created() {
    this.showOverlay = true;

    await this.singlePendingDeoVerification({
      ced_id: this.pendingVerificationId,
      deo_id: this.getUserId,
    })
      .then(async (res) => {
         console.log("res singlePendingDeoVerification:",res.data.data,);
        res.data.message = res.data.data
        this.showOverlay = true;
        // console.log(res.data.message, "res:");
        this.form.personName = res.data.message.extracted_data.Person_Name;
        this.form.fatherName = res.data.message.extracted_data.Father_Name;
        this.form.CNIC = res.data.message.extracted_data.ID_Card_Number;
        this.form.DOB = res.data.message.extracted_data.Date_of_Birth;
        this.form.cardRegisterationDate =
          res.data.message.extracted_data.Card_Registration_Date;
        this.form.gender = res.data.message.extracted_data.Gender;
        this.form.cardExpiryDate =
          res.data.message.extracted_data.Card_Expiry_Date;
        this.form.frontCardImage = res.data.message.front_card_gif;
        this.form.backCardImage = res.data.message.back_card_gif;
        this.form.frontImage = res.data.message.id_card;
        this.form.backImage = res.data.message.id_card_back;
        this.form.faceImage = res.data.message.face_gif;
        this.form.cardType = res.data.message.extracted_data.Card_Type;
        (this.form.verificationStatus = res.data.message.results.verified),
          (this.form.distance = res.data.message.results.distance),
          (this.form.maxThreshhold =
            res.data.message.results.max_threshold_to_verify),
          (this.showOverlay = false);
        this.dataReceived = await res;
        this.getAMlData()
        console.log("Form Data:", this.form);
        console.log("DataReceived", this.dataReceived);
      })
      .catch((e) => {
        console.log("error", e);
      });
  },
  mounted() {},
  methods: {
    ...mapActions("deo", [
      "singlePendingDeoVerification",
      "verifyPendingRequest",
      "pendingDeoVerifications",
    ]),
    viewAMLDetail(amlItem){
      this.amlDetailData = amlItem;
      this.amlDetailDialog = true;
      console.log("amlItem",amlItem)
    },
    async getAMlData() {
      let data = await this.dataReceived;
      let amlData = await data.data.message.aml_data;
      this.aml_data = [];
      for (let [key, value] of Object.entries(amlData)) {
        this.aml_data.push({
          name: key,
          value: value,
        });
      }
    },
    getIndividualAmlDetail({ item }) {
      this.showExpendedItem = item.name.toLowerCase();
    },
    viewSanctionDetail(item) {
      this.almSanctionDetailDialog = true;
      this.amlSanctionDetail = item;
    },
    viewPepDetail(item) {
      this.almPepDetailDialog = true;
      this.amlPepDetail = item;
    },
    viewDetail(item) {
      this.viewDetailDialog = true;
      this.kyb_detail = item;
    },
    pauseFaceImage() {
      let a = document.getElementById("face");
      if (this.button3 == "Pause") {
        this.form.faceImage = this.dataReceived.data.message.face_image;
        this.button3 = "Play";
      } else {
        this.form.faceImage = this.dataReceived.data.message.face_gif;
        this.button3 = "Pause";
      }
    },
    pauseFrontImage() {
      let b = document.getElementById("front");
      if (this.button1 == "Pause") {
        this.form.frontCardImage = this.dataReceived.data.message.id_card;
        this.button1 = "Play";
      } else {
        this.form.frontCardImage = this.dataReceived.data.message.front_card_gif;
        this.button1 = "Pause";
      }
    },
    pauseBackImage() {
      let c = document.getElementById("back");
      if (this.button2 == "Pause") {
        this.form.backCardImage = this.dataReceived.data.message.id_card_back;
        this.button2 = "Play";
      } else {
        this.form.backCardImage = this.dataReceived.data.message.back_card_gif;
        this.button2 = "Pause";
      }
      
    },
    sendVerifiedData(re) {
      let obj = {
        kyb_ids: this.selectedKYBMembers,
        sanction_ids: this.selectedSanctionMembers,
        pep_ids: this.selectedPepMembers,
        aml_ids: this.selectedAMLMembers,
        person_name: this.form.personName,
        father_name: this.form.fatherName,
        id_card_number: this.form.CNIC,
        date_of_birth: this.form.DOB,
        type: this.form.cardType,
        gender: this.form.gender,
        registration_date: this.form.cardRegisterationDate,
        expiry_date: this.form.cardExpiryDate,
        image_id: this.dataReceived.data.message.image_id,
        deo_remarks: re?re:['Person name is present in either KYB or AML'],
        face_data: this.dataReceived.data.message.results,
        service: "KYC",
        country: this.dataReceived.data.message.country,
        deo_id: this.getUserId,
        geoloc: this.dataReceived.data.message.geoloc,
        asn_data: this.dataReceived.data.message.asn_data,
        system_info: this.dataReceived.data.message.system_info,
        ced_id: this.dataReceived.data.message.ced_id,
        customer_id: this.dataReceived.data.message.customer_id,
        org_fullname: this.dataReceived.data.message.full_name,
        org_dob: this.dataReceived.data.message.dob,
        org_expiry: this.dataReceived.data.message.expiry_date,
      };

      this.verifyPendingRequest(obj)
        .then(async (res) => {
          console.log("res", res);
          await this.pendingDeoVerifications({ deo_id: this.getUserId })
            .then((res) => {
              this.$store.commit("deo/setPendingVerifications", res.data);
              this.$store.commit(
                "deo/setPendingCounter",
                res.data.message.length
              );

              this.$router.push({
                name: "PendingVerifications",
              });
            })
            .catch((e) => {
              console.log("error", e);
            });
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
  },
  computed: {
     isAllowedToVerify() {
      if(!this.dataReceived){
        return false
      }

      let data = this.dataReceived.data.message.checks;
      for (let key of Object.keys(data)) {
        if (!data[key]) {
          return false;
        }
      }
      return true;
    },
    ...mapGetters("auth", ["getUserId"]),
  },
};
</script>

<style lang="scss" scoped></style>
