<template>
  <div>
      <div class="d-flex ">
      <h1>In Active User</h1>
      <v-spacer></v-spacer>
      <v-btn class="mt-2" small rounded @click="activeUsers">show Active </v-btn>
      </div>
      <v-data-table
    :headers="headers"
    :items="branches"
    :items-per-page="10"
    class="elevation-1" 
  >
  <template v-slot:item.action="{ item }">
      
        <v-btn icon @click="viewInvoice(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn color="error" icon @click="activeUser(item)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import {mapActions,mapGetters,mapMutations} from 'vuex'
export default {
    data(){
        return{
            branches:[],
            headers: [
          {
            text: 'Business Name',
            align: 'start',
            sortable: false,
            value: 'businessname',
          },
          {
            text: 'User Name',
            align: 'start',
            sortable: false,
            value: 'username',
          },
          {
            text: 'User Email',
            align: 'start',
            sortable: false,
            value: 'useremail',
          },
          {
            text: 'User Contact',
            align: 'start',
            sortable: false,
            value: 'usercontact',
          },
          {
            text: 'Creation Date',
            align: 'start',
            sortable: false,
            value: 'creation_date',
          },
          {
            text: 'User Status',
            align: 'start',
            sortable: false,
            value: 'status',
          },
        //   {text:'Action',value:'action'}
        ],
        }
    },
    created(){
        this.getInActiveBranches();
    },
    computed:{
        ...mapGetters("business",["getBusinessId"])
    },
    methods:{
        ...mapActions("user",["inActivUser","activateUser"]),
        getInActiveBranches(){
            this.inActivUser(this.getBusinessId).then((res)=>{
                console.log("All inActive User",res)
                this.branches=res.data.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        viewInvoice(item){
          this.$emit("wantToShowDialog",true)
          let invoice = item
          console.log("invoice deatails",invoice)
          this.$store.commit('user/setUserId',invoice.buserid)
          // this.$router.push({
          //   name: "Business",
          //   params: { showComponent: "businesInvoice" },
          // });
        },
        activeUser(item){
            let buserID = item.buserid
            this.activateUser(buserID).then((res)=>{
                console.log("ActivateBusiness result",res)
                this.getInActiveBranches();
            }).catch((err)=>{
                console.log("error ActivateBusiness",err)
            })
        },
        activeUsers(){
          this.$router.push({
            name: "User",
            params: { showComponent: "active_user" },
          });
        },
    }
}
</script>

<style>

</style>