<template>
  <div>
    <v-row class="d-flex align-center mt-10">
      <v-col cols="12" sm="12" md="8" lg="9" xl="8" class="mx-auto">
        <v-card>
          <v-card-text>
            <v-subheader class="title font-weight-medium pa-0 ma-0"
              >New Menu</v-subheader
            >
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="12" xl="12"> 
                   <v-text-field
                   v-model="menuName"
                    label="Menu Name"
                    required
                    filled
                    ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="ml-2 mr-2">
          <v-btn  text @click="addMenu" :disabled="menuName== ''">
            <v-icon left>
                mdi-content-save
            </v-icon>Save
          </v-btn>
          <v-spacer></v-spacer>
         
           <v-btn color="error" text class="ml-2" @click="viewMenusComponent">Cancel</v-btn>
        </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data(){
        return {
            overlay: false,
            menuName: ''
        }
    },
    methods: {
        ...mapActions("roleMenuPermission", ["createMenu"]),
        addMenu(){
            this.createMenu({menu_name: this.menuName}).then(res => {
                // console.log('res', res)
                this.menuName = ''
                this.$store.commit('roleMenuPermission/setMenuSnackMessage', 'Menu Created Succesfully')
                this.$router.push({
                    name: 'Menus'
                })
            }).catch(e => {
                console.log('e', e)
            })
        },
        viewMenusComponent(){
            this.$router.push({
                name: 'Menus'
            })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>