<template>
  <div>
      <v-data-table
    :headers="headers"
    :items="business"
    :items-per-page="10"
    class="elevation-1"
  >
  <template v-slot:item.action="{ item }">
      
        <v-btn icon @click="viewInvoice(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn class="" small rounded @click="showBranches(item)">
          Show Branches
        </v-btn>
        <v-btn class="ml-2 mr-2" small rounded  @click="showUser(item)">
          Show User
        </v-btn>
        <v-btn color="error" icon @click="activateBusines(item)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import {mapActions,mapGetters,mapMutations} from 'vuex'
export default {
    data(){
        return{
            business:[],
            headers: [
          {
            text: 'Business Name',
            align: 'start',
            sortable: false,
            value: 'businessname',
          },
          {text:'Action',value:'action'}
        ],
        }
    },
    created(){
        this.getInActiveBusiness();
    },
    methods:{
        ...mapActions("business",["inActivBusiness","activateBusiness"]),
        getInActiveBusiness(){
            this.inActivBusiness().then((res)=>{
                console.log("All business",res.data.data)
                this.business=res.data.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        viewInvoice(item){
          let invoice = item
          console.log("invoice deatails",invoice)
          this.$store.commit('business/setBusinessId',invoice.business_id)
          this.$emit("wantToShowDialog",true)
          // this.$router.push({
          //   name: "Business",
          //   params: { showComponent: "businesInvoice" },
          // });
        },
        activateBusines(item){
            let businessID = item.business_id
            this.activateBusiness(businessID).then((res)=>{
                console.log("ActivateBusiness result",res)
                this.getInActiveBusiness();
            }).catch((err)=>{
                console.log("error ActivateBusiness",err)
            })
        },
        showBranches(item){
          let invoice = item
          console.log("invoice deatails",invoice)
          this.$store.commit('business/setBusinessId',invoice.business_id)

          this.$router.push({
            name: "Branches"
          });
        },
        showUser(item){
          let invoice = item
          console.log("invoice deatails",invoice)
          this.$store.commit('business/setBusinessId',invoice.business_id)

          // this.$router.push({
          //   name: "Business",
          //   params: { showComponent: "businesInvoice" },
          // });
        },
    }
}
</script>

<style>

</style>