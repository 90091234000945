<template>
  <div>
    <v-container fluid>
      <v-row>
      <v-col cols="12" v-if="!showComponent">
        <v-card>
          <v-tabs
            v-model="tab"
            align-with-title
            background-color="cyan"
          >

            <v-tab>
              All Business
            </v-tab>
            <v-tab>
              Active Business
            </v-tab>
            <v-tab>
              InActive Businesss
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card >
                <v-card-text class="pa-0" ><all-business @wantToShowDialog="dialog=$event"/></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card >
                <v-card-text class="pa-0" ><active-business @wantToShowDialog="dialog=$event"/></v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card >
                <v-card-text class="pa-0" ><in-activ-business @wantToShowDialog="dialog=$event"/></v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>  
      </v-col>
    </v-row>

      <v-dialog
      v-model="dialog"
      width="50%"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2 justify-space-between">
          <div>View Business</div>
          <div class="float-right">
            <v-btn
            icon
            color="primary"
            text
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </div>
        </v-card-title>

        <v-card-text>
          <view-business/>
        </v-card-text>
      </v-card>
    </v-dialog>
    </v-container>
  </div>

</template>

<script>
import viewBusiness from '../components/Business/viewBusiness.vue'
import inActivBusiness from '../components/Business/inActiveBusiness.vue'
import activeBusiness from '../components/Business/activeBusiness.vue'
import allBusiness from '../components/Business/allBusiness.vue'
export default {
  props:["showComponent"],
components:{
    allBusiness,
    activeBusiness,
    inActivBusiness,
    viewBusiness
},
data(){
  return{
     tab: null,
     dialog:false
  }
}
}
</script>

<style>

</style>