<template>
  <div>
    <div class="d-flex justify-end">
        <v-btn class="mr-5" small rounded @click="activeUser">show Active </v-btn>
    <v-btn  small rounded @click="inActiveUser">show InActive </v-btn>
    </div>
      <v-data-table
    :headers="headers"
    :items="user"
    :items-per-page="10"
    class="elevation-1 mt-3"
  >
  <!-- <template v-slot:item.action="{ item }">
      
        <v-btn icon color="primary" @click="viewInvoice(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
    </template> -->
  </v-data-table>
  </div>
</template>

<script>
import {mapActions,mapGetters,mapMutations} from 'vuex'
export default {
    data(){
        return{
            user:[],
            headers: [
          {
            text: 'Business Name',
            align: 'start',
            sortable: false,
            value: 'businessname',
          },
          {
            text: 'User Name',
            align: 'start',
            sortable: false,
            value: 'username',
          },
          {
            text: 'User Email',
            align: 'start',
            sortable: false,
            value: 'useremail',
          },
          {
            text: 'User Contact',
            align: 'start',
            sortable: false,
            value: 'usercontact',
          },
          {
            text: 'Creation Date',
            align: 'start',
            sortable: false,
            value: 'creation_date',
          },
          {
            text: 'User Status',
            align: 'start',
            sortable: false,
            value: 'status',
          },
        //   {text:'Action',value:'action'}
        ],
        }
    },
    created(){
        this.getUser();
    },
    computed:{
        ...mapGetters("business",["getBusinessId"])
    },
    methods:{
        ...mapActions("user",["allUser"]),
        getUser(){
            this.allUser(this.getBusinessId).then((res)=>{
                console.log("All User",res)
                this.user=res.data.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        viewInvoice(item){
            
          this.$emit("wantToShowDialog",true)
          let invoice = item
          console.log("invoice deatails",invoice)
          this.$store.commit('user/setUserId',invoice.buserid)

          // this.$router.push({
          //   name: "Business",
          //   params: { showComponent: "businesInvoice" },
          // });
        },
        activeUser(){
          this.$router.push({
            name: "User",
            params: { showComponent: "active_user" },
          });
        },
        inActiveUser(){
          this.$router.push({
            name: "User",
            params: { showComponent: "inactive_user" },
          });
        }
    }
}
</script>

<style>

</style>