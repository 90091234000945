<template>
    <div>

         <v-navigation-drawer width="350" clipped right app>
     <template v-slot:prepend>
        <v-subheader class="subtitle-1 font-weight-medium">User Detail</v-subheader>
      </template>
      <v-divider></v-divider>
       <v-list flat>
       <v-subheader class="subtitle-2 font-weight-medium">Main Detail</v-subheader>
  <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="subtitle-2">Full Name</v-list-item-subtitle>
                 <v-list-item-title class="font-weight-medium">{{form.fullName}}  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
           <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="subtitle-2">User Address</v-list-item-subtitle>
                 <v-list-item-title class="font-weight-medium">{{ form.address }} </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
       </v-list>
       <v-subheader class="subtitle-2 font-weight-medium">Rejection Remarks</v-subheader>
       <div class="pa-4">
             <v-select
                      v-model="form.remarks"
                      :items="rejectItems"
                      filled
                      label="Rejection Remarks"
                      multiple
                    >
                    </v-select>
       </div>
        <template v-slot:append>
    <div class="px-5 py-3 grey lighten-4">
          <v-btn
                   class="text-capitalize primary"
          color="primary"
                  @click="sendVerifiedData(['verified'])"
                  ><v-icon left>mdi-check-decagram</v-icon> Verify
                </v-btn>
       <v-btn
                  class="text-capitalize mr-0 float-right"
               color="error"
                  @click="sendVerifiedData(form.remarks)"
                  :disabled="form.remarks == ''"
                  ><v-icon left>mdi-close-octagon</v-icon> Reject</v-btn
                >
            
    </div>
        </template>
       
         </v-navigation-drawer>
          <v-toolbar dense class="mt-4 mb-4 transparent font-weight-medium" flat >
      <v-toolbar-title>Address Verification
      </v-toolbar-title>
          </v-toolbar>

        <v-row>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
             <v-hover v-slot="{ hover }">
                <v-card rounded :elevation="hover ? 6 : 2">
                     <v-card-title class="pt-3 pb-2 subtitle-1 font-weight-medium">
             Address Video
            </v-card-title>
                    <v-divider></v-divider>
                  <v-card-text>
                  <div class="text-center">
                    <!-- <img
                    :src="`${form.addressImage}`"
                    alt=""
                    width="100%"
                    height="400px"
                    class="extracted-img"
                  /> -->
                  <video
                    width="100%"
                    height="400px"
                    class="extracted-img"
                    controls
                    loop
                  >
                    <source :src="`${form.addressImage}`" type="video/mp4">
                    <source  :src="`${form.addressImage}`" type="video/webm">
                    <source  :src="`${form.addressImage}`" type="video/ogg">
                    Video tag is not supported in this browser.
                  </video>
                  </div>
                  </v-card-text>
                </v-card>
             </v-hover>
   </v-col>
<v-col cols="12" sm="12" md="12" lg="8" xl="8">
            <v-card>
              <v-card-title class="pt-3 pb-2 subtitle-1 font-weight-medium">
              Extracted Details
            </v-card-title>
            <v-divider></v-divider>
              <v-card-text>
                <v-row>
   <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      label="Person Name"
                      dense
                      v-model="form.fullName"
                      filled
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      label="Address"
                      dense
                      filled
                      v-model="form.address"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                    filled
                    label="DEO Remarks"
                    v-model="form.remarks"
                    rows="6"
                    no-resize
                    hide-details="auto"
                  ></v-textarea>
                   
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-card>
</v-col>
    </v-row>
    <v-overlay :value="showOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
    export default {
        props: ['pendingAddressVerificationId'],
        data(){
            return {
                showOverlay: false,
                dataReceived: null,
                rejectItems: [
                    "Face is different",
                    "Image is Blur",
                    "Not Similar",
                    "Rejected",
                    "No Data Extracted",
                    "Other",
                ],
                form: {
                    fullName: "",
                    addressImage: "",
                    address: "",
                    remarks: "",
                },
                button1: "Pause",
            }
        },
        async created(){
            this.showVerificationData();
        },
        methods: {
            ...mapActions("deo", ["singleAddressDeoVerification", "verifyAddressRequest", "addressPendingVerifications"]),
            showVerificationData(){
                this.showOverlay= true;
                this.singleAddressDeoVerification({
                    address_id: this.pendingAddressVerificationId,
                    deo_id: this.getUserId
                }).then(async (res) => {
                    console.log(res.data)
                    this.form.address = res.data.message.address;
                    this.form.fullName = res.data.message.full_name;
                    this.form.addressImage = res.data.message.document_gif;
                    this.dataReceived = res.data;

                this.showOverlay= false;
                }).catch(e => {
                    console.log('error',e)
                })
            },
            sendVerifiedData(re) {
            // console.log("Data Recievied: me::", this.dataReceived);
            let obj = {
                customer_id: this.dataReceived.message.customer_id,
                image_id: this.dataReceived.message.image_id,
                geoloc: this.dataReceived.message.geoloc,
                asn_data: this.dataReceived.message.asn_data,
                system_info: this.dataReceived.message.system_info,
                date_of_birth: this.dataReceived.message.date_of_birth,
                full_name: this.form.fullName,
                address: this.form.address,
                deo_remarks: re,
                address_id: this.dataReceived.message.address_id,
                deo_id: this.getUserId,
                country: this.dataReceived.message.country,
                business_id: this.dataReceived.message.business_id,
                ced_id: this.dataReceived.message.ced_id,
                branch_id: this.dataReceived.message.branch_id,
                user_id: this.dataReceived.message.user_id,
                deo_id: this.dataReceived.message.deo_id,
                channel_id: this.dataReceived.message.channel_id,
            };
            this.verifyAddressRequest(obj).then(async (res) => {
                console.log("address pending response: ", res);
                await this.addressPendingVerifications().then((res) => {
                    // console.log('response: ', res)
                    this.$store.commit("deo/setPendingVerifications", res.data)
                    this.$router.push({
                    name: 'PendingVerifications',
                })
                    // this.pendingVerificationsInfo =  res.data
                }).catch(e => {
                    console.log('error',e)
                })
                
            });
            },
            pauseVideo() {
            // let a = this.$refs.myBtn
            let b = document.getElementById("pau");
            // console.log(b.innerHTML)
            if (this.button1 == "Pause") {
                this.form.addressImage = this.dataReceived.message.document_image;
                this.button1 = "Play";
            } else {
                this.form.addressImage = this.dataReceived.message.document_gif;
                this.button1 = "Pause";
            }
            },
        },
        computed: {
            ...mapGetters("auth", ["getUserId"])
        }
    }
</script>

<style lang="scss" scoped>

</style>