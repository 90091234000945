<template>
  <div>
      <v-container fluid>
      <all-branches v-if="!showComponent" @wantToShowDialog="dialog=$event" />
      <active-branches v-if="showComponent=='activeBranches'"  @wantToShowDialog="dialog=$event" />
      <in-active-branches v-if="showComponent=='inActiveBranches'" @wantToShowDialog="dialog=$event" />

       <v-dialog
      v-model="dialog"
      width="50%"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2 justify-space-between">
          <div>View Branches</div>
          <div class="float-right">
            <v-btn
            icon
            color="primary"
            text
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </div>
        </v-card-title>

        <v-card-text>
          <view-branches/>
        </v-card-text>
      </v-card>
    </v-dialog>
      </v-container>
  </div>
</template>

<script>
import activeBranches from '../components/Branches/activeBranches.vue'
import inActiveBranches from '../components/Branches/inActiveBranches.vue'
import allBranches from '../components/Branches/allBranches.vue'
import viewBranches from '../components/Branches/viewBranches.vue'
export default {
  props:["showComponent"],
    data(){
        return{
            dialog:false
        }
    },
    components:{
        allBranches,
        viewBranches,
        inActiveBranches,
        activeBranches
        
    }
}
</script>

<style>

</style>