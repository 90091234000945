var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[(!_vm.showComponent)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-toolbar',{staticClass:"mt-4 transparent toolbarStyle",attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Pending Verifications ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.activeDisabler},on:{"click":_vm.showDocumentPendingVerifications}},[_c('v-icon',[_vm._v("mdi-file-document")]),_vm._v(" Document ")],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.activeDisabler,"color":"primary"},on:{"click":_vm.showAddressPendingVerifications}},[_c('v-icon',[_vm._v("mdi-google-maps")]),_vm._v(" Address ")],1)],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.getPendingVerifications.data,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"loading":_vm.fetchingVerification},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.getPendingVerifications.type == 'document')?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize primary--text",attrs:{"elevation":hover ? 6 : 0,"color":"white","small":""},on:{"click":function($event){return _vm.showDetails(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-eye")]),_vm._v(" Details")],1)]}}],null,true)})]}}:{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize primary--text",attrs:{"elevation":hover ? 6 : 0,"color":"white","small":""},on:{"click":function($event){return _vm.showAddressDetails(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-eye")]),_vm._v(" Details")],1)]}}],null,true)})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.showComponent == 'single-address-verification')?_c('single-address-verification',{attrs:{"pendingAddressVerificationId":_vm.pendingAddressVerificationId}}):_vm._e(),(_vm.showComponent == 'single-pending-verification')?_c('single-pending-verification',{attrs:{"pendingVerificationId":_vm.pendingVerificationId}}):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.showOverlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }