<template>
    <div>
        <div class="d-flex">
            <h3>{{role.role_name}} Details</h3>
            <v-spacer></v-spacer>
            <v-btn class="error" :to="{name: 'Roles'}">Exit</v-btn>

        </div>
        <div class="d-flex">
              <v-row>
                <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto">
                
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Dashboard</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.insert"
                          :disabled="true"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.display"
                          :disabled="true"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.update"
                          :disabled="true"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.delete"
                          :disabled="true"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
                  <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto">
                
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Deo</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="deoPermissions.insert"
                          class="flex-end"
                          :disabled="true"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          :disabled="true"
                          v-model="deoPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          :disabled="true"
                          v-model="deoPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          :disabled="true"
                          v-model="deoPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
                  <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto">
                
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Pending Verifications</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          :disabled="true"
                          v-model="pendingPermissions.insert"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          :disabled="true"
                          v-model="pendingPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          :disabled="true"
                          v-model="pendingPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          :disabled="true"
                          v-model="pendingPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
              </v-row>
            </div>

            <div class="d-flex">
              <v-row>
                <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class=""
                >
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Roles</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.insert"
                          :disabled="true"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.display"
                          :disabled="true"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.update"
                          :disabled="true"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          :disabled="true"
                          v-model="rolesPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
              </v-row>
            </div>
        
            <!-- <v-row class="ma-5">
                <v-col cols="6" sm="6" md="6" lg="6" xl="6" v-for="ro in role.totaldata" :key="ro">

                
        <v-card max-width="400" class="mx-auto" >
            <v-card-title>{{ro.menuitems_name}}</v-card-title>
            <v-card-subtitle>{{ro.status}}</v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="12" xl="12">
                        <v-list-item>
                        <v-list-item-title>Display Permision</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="ro.displaypermission"
                            class="flex-end"
                            :disabled="true"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permision</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="ro.deletepermission"
                            class="flex-end"
                            :disabled="true"

                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permision</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="ro.updatepermission"
                            class="flex-end"
                            :disabled="true"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Insert Permision</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="ro.insertpermission"
                            class="flex-end"
                            :disabled="true"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        </v-col>
            </v-row> -->
        <!-- {{role.totaldata}} -->
    </div>
</template>

<script>
    export default {
        props: ["role"],
        data(){
            return {
            dashboardPermissions: {
              insert: false,
              display: false,
              update: false,
              delete: false,
            },
            deoPermissions: {
              insert: false,
              display: false,
              update: false,
              delete: false,
            },
            pendingPermissions: {
              insert: false,
              display: false,
              update: false,
              delete: false,
            },
            rolesPermissions: {
              insert: false,
              display: false,
              update: false,
              delete: false,
            },
            }
        },
        created()
        {
          this.dashboardPermissions.insert = this.role.totaldata[0].insertpermission
          this.dashboardPermissions.update = this.role.totaldata[0].updatepermission
          this.dashboardPermissions.delete = this.role.totaldata[0].deletepermission
          this.dashboardPermissions.display = this.role.totaldata[0].displaypermission

          this.deoPermissions.insert = this.role.totaldata[1].insertpermission
          this.deoPermissions.update = this.role.totaldata[1].updatepermission
          this.deoPermissions.delete = this.role.totaldata[1].deletepermission
          this.deoPermissions.display = this.role.totaldata[1].displaypermission

          this.pendingPermissions.insert = this.role.totaldata[2].insertpermission
          this.pendingPermissions.update = this.role.totaldata[2].updatepermission
          this.pendingPermissions.delete = this.role.totaldata[2].deletepermission
          this.pendingPermissions.display = this.role.totaldata[2].displaypermission

          this.rolesPermissions.insert = this.role.totaldata[3].insertpermission
          this.rolesPermissions.update = this.role.totaldata[3].updatepermission
          this.rolesPermissions.delete = this.role.totaldata[3].deletepermission
          this.rolesPermissions.display = this.role.totaldata[3].displaypermission
        },
    }
</script>

<style lang="scss" scoped>

</style>
