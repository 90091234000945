<template>
  <div>
    <v-container>
      <v-row v-if="!showComponent">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
      <v-toolbar dense class="mt-4 transparent toolbarStyle"  flat>
      <v-toolbar-title>Pending Verifications
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
       <v-btn
        color="primary"
        @click="showDocumentPendingVerifications"
        :disabled="activeDisabler"
        >
        <v-icon>mdi-file-document</v-icon>   Document
        </v-btn>
        <v-divider vertical></v-divider>
         <v-btn
          :disabled="!activeDisabler"
          color="primary"
          class="ml-2"
          @click="showAddressPendingVerifications"
          >
           <v-icon>mdi-google-maps</v-icon>   Address
              </v-btn>
</v-toolbar-items>
     
    </v-toolbar>
          <v-card class="mt-4">
             <v-data-table
                :headers="headers"
                :items="getPendingVerifications.data"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :search="search"
                :loading="fetchingVerification"
                class="elevation-0"
              >

                <template v-slot:[`item.action`]="{ item }" v-if="getPendingVerifications.type == 'document'">
                  <v-hover v-slot="{ hover }">
                  <v-btn :elevation="hover ? 6 : 0" color="white"  class="text-capitalize primary--text" small @click="showDetails(item)"
                    ><v-icon left small>mdi-eye</v-icon>  Details</v-btn
                  >
                  </v-hover>
                </template>
                <template v-slot:[`item.action`]="{ item }" v-else>
                  <v-hover v-slot="{ hover }">
                  <v-btn :elevation="hover ? 6 : 0" color="white"  class="text-capitalize primary--text" small  @click="showAddressDetails(item)"
                    ><v-icon left small>mdi-eye</v-icon> Details</v-btn>
                   </v-hover>
                </template>

            <template v-slot:item.status="{ item }">
              <v-chip
                :color="getColor(item.status)"
                dark
                >
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
      </v-card>
    </v-col>
  </v-row>
  <single-address-verification v-if="showComponent == 'single-address-verification'" :pendingAddressVerificationId="pendingAddressVerificationId"></single-address-verification>
  <single-pending-verification v-if="showComponent == 'single-pending-verification'" :pendingVerificationId="pendingVerificationId"></single-pending-verification>
</v-container>
<v-overlay :value="showOverlay">
  <v-progress-circular indeterminate size="64"></v-progress-circular>
</v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SinglePendingVerification from '../../components/DeoComponents/singlePendingVerification.vue';
import SingleAddressVerification from '../../components/DeoComponents/singleAddressVerification.vue';

export default {
  props: ['showComponent'],
  data() {
    return {
      sortBy: 'creation_date',
      sortDesc: true,
      showOverlay: false,
      fetchingVerification: true,
      search: "",
      activeDisabler: true,
      pendingVerificationId: "",
      pendingAddressVerificationId: "",
      pendingVerificationsInfo: [],
      headers: [
        { text: "Name", value: "personname" },
        { text: "Country", value: "thecountry" },
        { text: "Creation Date", value: "creation_date" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "action" },
      ],
    };
  },
  components: 
  {
    SinglePendingVerification,
    SingleAddressVerification,
  },
  created() {
    this.showDocumentPendingVerifications();
  },
  methods: {
    ...mapActions("deo", ["pendingDeoVerifications", "addressPendingVerifications"]),
    showDocumentPendingVerifications(){
      this.activeDisabler = true;
      this.pendingVerificationsInfo = []
      this.fetchingVerification = true;
      this.pendingDeoVerifications({ deo_id: this.getUserId })
      .then((res) => {
        this.showOverlay = false;
        console.log('showDocumentPendingVerifications of response', res.data.data)
        this.fetchingVerification = false;
        // this.pendingVerificationsInfo = res.data;
        this.$store.commit("deo/setPendingVerifications", res.data)
        console.log( "res bfdgdjgdjvd",res);
        // this.pendingVerificationsList = res.data.message.creation_date;
      })
      .catch((e) => {
        this.showOverlay = false;
        console.log(e, "error");
      });
    },
    showAddressPendingVerifications(){
      this.activeDisabler = false;
        this.fetchingVerification = true;
      this.pendingVerificationsInfo = []
      this.addressPendingVerifications().then((res) => {
        console.log('response: addressPendingVerifications', res)
        this.fetchingVerification = false;
        this.$store.commit("deo/setPendingVerifications", res.data)
        // this.pendingVerificationsInfo =  res.data
      }).catch(e => {
        console.log('error',e)
      })
    },
    showDetails(item){
      // console.log(item)
      this.pendingVerificationId = item.cedid
      this.$router.push({
        name: 'PendingVerifications',
        params: {showComponent : 'single-pending-verification'}
      })
    },
    showAddressDetails(item){
      // console.log(item)
      this.pendingAddressVerificationId = item.addressid;
      this.$router.push({
        name: 'PendingVerifications',
        params: {showComponent : 'single-address-verification'}
      })
    },
    getColor (status) {
        if (status == 'Pending') return 'orange'
        else return 'red'
      },

  },
  computed: {
    ...mapGetters("auth", ["getUserId"]),
    ...mapGetters("deo", ["getPendingVerifications"])
  },
};
</script>

<style lang="scss">
.toolbarStyle .v-toolbar__content{
padding-left: 0px !important;
padding-right: 0px !important;
}
</style>