<template>
  <div>
    <v-container>
      <v-row style="height: 100vh" class="d-flex align-center">
           <v-col v-if="this.apiSuccess" cols="12" sm="12" md="12" lg="12" xl="12">
          <v-alert dense outlined class="showAlert mx-15" type="success">
            {{ apiSuccess }}
          </v-alert>
        </v-col>
        <v-col v-if="this.apiError" cols="12" sm="12" md="12" lg="12" xl="12">
          <v-alert dense outlined class="showAlert mx-15" type="error">
            {{ apiError }}
          </v-alert>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mx-auto">
          <v-card elevation="1">
            <v-card-title class="justify-center">DEO Registration</v-card-title>
            <v-card-text>
              <v-row>
         <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field 
              type="text" 
              label="First Name" 
              v-model="fname"
              filled
              hide-details="auto">
              </v-text-field>
         </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field 
              type="text" 
              v-model="lname" 
              label="Last Name"
               filled
              hide-details="auto">
              </v-text-field>
         </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
             <v-text-field
                type="text"
                label="User Name"
                @input="$v.username.$touch()"
                :error-messages="usernameErrors"
                @blur="$v.username.$touch()" 
                v-model="username"
                 filled
              hide-details="auto"
              >
              </v-text-field>
         </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
                type="email"
                @input="$v.email.$touch()"
                :error-messages="emailErrors"
                @blur="$v.email.$touch()" 
                label="Email"
                v-model="email"
                 filled
              hide-details="auto"
                
              >
              </v-text-field>
         </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6" xl="6">
             <v-text-field
                v-model="password"
                :type="eyeIcon ? 'text' : 'password'"
                :append-icon="eyeIcon ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="eyeIcon = !eyeIcon"
                label="Password"
                required
                 filled
              hide-details="auto"
              ></v-text-field>
         </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
                v-model="password_confirmation"
                :type="confirmEyeIcon ? 'text' : 'password'"
                :append-icon="confirmEyeIcon ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="confirmEyeIcon = !confirmEyeIcon"
                label="Confirm Password"
                 filled
              hide-details="auto"
              ></v-text-field>
         </v-col> -->
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
                v-model="contact"
                label="Contact"
                type="text"
                 filled
              hide-details="auto"
              ></v-text-field>
         </v-col>
         <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
                v-model="country"
                label="Country"
                filled
                hide-details="auto"
              ></v-text-field>
         </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
                v-model="city"
                label="City"
                filled
                hide-details="auto"
              ></v-text-field>
         </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
                v-model="region"
                label="Region"
                filled
                hide-details="auto"
              ></v-text-field>
         </v-col>
       </v-row>
            </v-card-text>
            <v-card-actions class="mx-2">
              <v-btn
                block
                class="mb-3"
                type="submit"
                @click.prevent="signingUp"
                :disabled="
                  username == '' || email == '' || country == '' || city == '' || region == '' ||
                  usernameErrors.length > 0 || emailErrors.length > 0 || regionErrors.length > 0 || cityErrors.length > 0 || countryErrors.length > 0
                "
                :loading="isRegistering"
                depressed
                color="primary"
              >
                Register
              </v-btn>
            </v-card-actions>
            <!-- <v-row>
              <v-col>
                <v-col class="pb-0">
                  <p class="text-center">
                    Do you have an account?
                    <v-btn
                      class="ml-2"
                      color="primary"
                      text
                      :to="{ name: 'signIn' }"
                      >Login</v-btn
                    >
                  </p>
                </v-col>
              </v-col>
            </v-row> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {mapGetters} from 'vuex'
import {
  required,
  maxLength,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data() {
    return {
      username: "",
      email: "",
      country: "",
      city: "",
      region: "",
      fname: "",
      lname: "",
      contact: "",
    //   password: "",
    //   password_confirmation: "",
      ismatch: false,
      eyeIcon: false,
      confirmEyeIcon: false,
      isRegistering: false,
      apiError: null,
      apiSuccess: null,
      alert: true,
    };
  },
  computed: {
  },
  mounted(){
    setTimeout(()=>{
      this.apiError = null
      this.apiSuccess = null
      this.alert=false
    },7000)
  },
  methods: {
    ...mapActions("deo", ["register"]),
    signingUp() {
      this.isRegistering = true;
      if (this.$v.$anyError) return;
      else{
        let obj = {
          firstName: this.fname,
          lastName: this.lname,
          userName: this.username,
          userEmail: this.email,
        //   password: this.password,
          contact: this.contact,
          country: this.country,
          city: this.city,
          region: this.region,
        }
        this.register(obj).then(res => {
        //   console.log(res, "response:")
        //   this.$store.commit('auth/setRegistrationSnack', true)
          this.isRegistering = false;
          this.apiSuccess = "Deo Registered Successfully"
          this.resetFormData();
        //   this.$router.push({
        //     name: 'signIn'
        //   })
        }).catch(e => {
          console.log(e, "error:")
          this.isRegistering = false
          this.apiError = e
          setTimeout(() => {
            this.apiError = null
          }, 7000);
        })
      }
    },
    resetFormData() {
      (this.fname = ""),
        (this.lname = ""),
        (this.username = ""),
        (this.email = ""),
        // (this.password_confirmation = ""),
        // (this.password = ""),
        (this.contact = ""),
        (this.country = ""),
        (this.city = "");
    },
  },
  validations: {
    username: { required, maxLength: maxLength(40) },
    email: { required, email },
    city: {required},
    region: {required},
    country: {required}
  },
  computed: {
    regionErrors() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      !this.$v.region.required && errors.push("Region name is required");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("City name is required");
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.country.$dirty) return errors;
      !this.$v.country.required && errors.push("Country name is required");
      return errors;
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.maxLength &&
        errors.push("User name must be at most 40 characters long");
      !this.$v.username.required && errors.push("User name is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required");
      !this.$v.email.email && errors.push("Must be valid e-mail");
      return errors;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
