<template>
        <v-container>
            <view-all @roleDetails="onRoleClick" v-if="!showComponent"></view-all>
            <create v-else-if="showComponent == 'create'"></create>
            <edit :role="roleDetail" v-else-if="showComponent == 'edit'"></edit>
            <view-single :role="roleDetail" v-else-if="showComponent == 'view'"></view-single>
        </v-container>
</template>

<script>
import viewAll from "../../components/Roles/viewAll.vue"
import edit from "../../components/Roles/edit.vue"
import create from "../../components/Roles/create.vue"
import ViewSingle from '../../components/Roles/viewSingle.vue'

    export default {
        props: ["showComponent"],
        data(){
            return {
            roleDetail: null
        }},
        components: { viewAll, edit, create, ViewSingle },
        methods: {
            onRoleClick(value){
                this.roleDetail = value;
                // console.log('role Detail1', this.roleDetail)

            }
        }
        
    }
</script>

<style lang="scss" scoped>

</style>