<template>
  <div>
    <v-row class="d-flex align-center mt-10">
      <v-col cols="12" sm="12" md="8" lg="9" xl="8" class="mx-auto">
        <v-card>
          <v-card-text>
            <v-subheader class="title font-weight-medium pa-0 ma-0"
              >Edit Role</v-subheader
            >
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="roleName"
                  label="Role Name"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-subheader
              class="font-weight-medium pa-0 ma-0"
              style="height: 20px"
            >
              All Menus</v-subheader
            >
            <!-- <div class="d-flex">
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto"
                  v-for="(menu, index) in role.totaldata"
                  :key="menu.meniuid"
                >
                  <v-card class="mx-auto align-left my-5" width="400">
                    <v-card-title class="title">
                      {{ menu.menuitems_name }}
                    </v-card-title>
                    <v-card-subtitle>{{menu.status}}</v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="menu.displaypermission"
                            class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="menu.deletepermission"
                            class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="menu.updatepermission"
                            class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="menu.insertpermission"
                            class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div> -->

             <div class="d-flex">
              <v-row>
                <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto">
                
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Dashboard</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.insert"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="dashboardPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
                  <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto">
                
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Deo</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="deoPermissions.insert"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="deoPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="deoPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="deoPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
                  <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto">
                
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Pending Verifications</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="pendingPermissions.insert"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="pendingPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="pendingPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="pendingPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
              </v-row>
            </div>

            <div class="d-flex">
              <v-row>
                <v-col cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  class="mx-auto"
                >
                  <v-card class="mx-auto align-left my-5" width="300">
                    <v-card-title>Roles</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-0">
                      <v-list-item>
                        <v-list-item-title>Insert Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.insert"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Display Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.display"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Update Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.update"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Delete Permission</v-list-item-title>
                        <v-list-item-action>
                          <v-checkbox
                          v-model="rolesPermissions.delete"
                          class="flex-end"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                  </v-col>
              </v-row>
            </div>
          </v-card-text>
          <!-- {{this.componentMenus.data}} -->
          <v-card-actions class="ml-2 mr-2">
            <v-btn text @click="updatingRole" :disabled="roleName == ''">
              <v-icon left> mdi-content-save </v-icon>Save
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn color="error" text class="ml-2" @click="viewRolesComponent"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
        <!-- {{role}} -->
      </v-col>
    </v-row>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["role"],
  data() {
    return {
      myValue:[],
      roleName: "",
      overlay: true,
      checkbox: false,
      componentMenus: null,
      menuPermissions: [
        {permission: "Insert Permission" },
        {permission: "Display Permission"},
        {permission: "Update Permission"},
        {permission: "Delete Permission"},
      ],
      dashboardPermissions: {
        insert: false,
        display: false,
        update: false,
        delete: false,
      },
      deoPermissions: {
        insert: false,
        display: false,
        update: false,
        delete: false,
      },
      pendingPermissions: {
        insert: false,
        display: false,
        update: false,
        delete: false,
      },
      rolesPermissions: {
        insert: false,
        display: false,
        update: false,
        delete: false,
      },
    };
  },
  created() {
    this.gettingSingleRole();
    this.roleName = this.role.role_name;
    // console.log('Role details', this.role)
  },
  computed: {
    ...mapGetters("roleMenuPermission", ["getRoles"]),
  },
  methods: {
    ...mapActions("roleMenuPermission", ["singleRole", "updateRole"]),
    gettingSingleRole() {
      this.singleRole(this.role.role_id)
        .then((res) => {
          this.overlay = false;
          // console.log('res', res)
          let response = res.data.data
          this.componentMenus = response;
          
          this.dashboardPermissions.insert = response[0].insertpermission
          this.dashboardPermissions.display = response[0].displaypermission
          this.dashboardPermissions.update = response[0].updatepermission
          this.dashboardPermissions.delete = response[0].deletepermission

          this.deoPermissions.insert = response[1].insertpermission
          this.deoPermissions.display = response[1].displaypermission
          this.deoPermissions.update = response[1].updatepermission
          this.deoPermissions.delete = response[1].deletepermission

          this.pendingPermissions.insert = response[2].insertpermission
          this.pendingPermissions.display = response[2].displaypermission
          this.pendingPermissions.update = response[2].updatepermission
          this.pendingPermissions.delete = response[2].deletepermission

          this.rolesPermissions.insert = response[3].insertpermission
          this.rolesPermissions.display = response[3].displaypermission
          this.rolesPermissions.update = response[3].updatepermission
          this.rolesPermissions.delete = response[3].deletepermission
          //setting response to values
          // this.dashboardPermissions.insert =
          // let response=res.data.data
          // response.forEach(val=>{
          //   this.myValue.push([])
          // })
          // this.$store.commit("roleMenuPermission/setRoles", res.data.data);
          // for (let i = 0; i < this.componentMenus.data.length; i++) 
          // {
          //   this.componentMenus.data[i].permissions = this.menuPermissions;
          // }
        })
        .catch((e) => {
          this.overlay = false;
          console.log("e", e);
        });
    },
    updatingRole(){
      // console.log('data', this.role)
      // console.log('role', this.role)
          this.overlay = true;

      let obj = {
        id: this.role.role_id,
        name: this.role.role_name,
        permissions: [
          {
            menu_id: this.componentMenus[0].menu_id,
            displaypermission : this.dashboardPermissions.display,
            insertpermission: this.dashboardPermissions.insert,
            updatepermission: this.dashboardPermissions.update,
            deletepermission: this.dashboardPermissions.delete
          },
          {
            menu_id: this.componentMenus[1].menu_id,
            displaypermission : this.deoPermissions.display,
            insertpermission: this.deoPermissions.insert,
            updatepermission: this.deoPermissions.update,
            deletepermission: this.deoPermissions.delete
          },
          {
            menu_id: this.componentMenus[2].menu_id,
            displaypermission : this.pendingPermissions.display,
            insertpermission: this.pendingPermissions.insert,
            updatepermission: this.pendingPermissions.update,
            deletepermission: this.pendingPermissions.delete
          },
          {
            menu_id: this.componentMenus[3].menu_id,
            displaypermission : this.rolesPermissions.display,
            insertpermission: this.rolesPermissions.insert,
            updatepermission: this.rolesPermissions.update,
            deletepermission: this.rolesPermissions.delete
          },
        ]
      }
      // console.log('component object', obj)
      this.updateRole(obj).then(res => {
        // console.log('response:', res)
          this.overlay = false;
        this.$router.push({
          name: 'Roles'
        })
        this.$store.commit(
            "roleMenuPermission/setRoleSnackMessage",
            "Role Updated Succesfully"
          );
      }).catch(e=> {
        console.log('error',e)
        this.$router.push({
          name: 'Roles'
        })
          this.overlay = false;
        this.$store.commit(
            "roleMenuPermission/setRoleSnackMessage",
            "Error Updated Role"
          );
      })
    },
    viewRolesComponent() {
      this.$router.push({
        name: "Roles",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
