<template>
  <div>
    <!-- <v-container class="mt-5">
            <h1>Verification detail</h1>
            {{verificationDetail}}
        </v-container> -->
    <v-container>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
        <v-overlay :value="showOverlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Request Details </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="changeVerification"> Close </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <!-- {{verificationDetail}} -->
              <v-toolbar class="transparent pa-0" flat>
                <v-toolbar-title
                  >{{ verificationDetail.Person_Name }} your Verification Status
                  is
                  <span>
                    <v-btn
                      class="white--text ml-0 text-capitalize"
                      rounded
                      depressed
                      small
                      :color="
                        verificationDetail.verified_data.status == 'verified'
                          ? 'success'
                          : 'error'
                      "
                      >{{ verificationDetail.verified_data.status }}</v-btn
                    >
                  </span></v-toolbar-title
                >
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-row class="mb-15">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-subheader class="section-header"
                            >General Location</v-subheader
                          >
                          <table class="General-Location">
                            <tr>
                              <td class="header">Country Name:</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.country_name
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Region Name:</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.sub_div_name
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">City</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.city_name
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Continent Name</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.continent
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Time Zone</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.time_zone
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Latitude</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.latitude
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Longitutude</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.longitude
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">IP</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.asndata
                                    ? verificationDetail.asndata.network
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">ISP</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.asndata
                                    ? verificationDetail.asndata.asn_name
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Country Code:</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.iso_code
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Region Code</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.sub_div_iso_code
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Postal Code</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.geoloc
                                    ? verificationDetail.geoloc.postal_code
                                    : ""
                                }}
                              </td>
                            </tr>
                          </table>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-subheader class="section-header"
                          >Services</v-subheader>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                              <v-subheader class="services-subheader"
                                >User Details</v-subheader
                              >
                              <div class="d-flex mt-n2 mt-4">
                                <v-checkbox
                                  label="Name"
                                  v-model="services"
                                  disabled
                                  hide-details
                                ></v-checkbox>
                                <v-checkbox
                                  label="Date of birth"
                                  v-model="services"
                                  disabled
                                  hide-details
                                  class="ml-6"
                                ></v-checkbox>
                              </div>
                              <v-subheader class="services-subheader mt-6"
                                >Face Details</v-subheader
                              >
                              <div class="d-flex mt-n2 mt-4">
                                <v-checkbox
                                  label="Face"
                                  v-model="services"
                                  disabled
                                  hide-details
                                ></v-checkbox>
                                <v-checkbox
                                  label="Face on Document Matched"
                                  v-model="services"
                                  disabled
                                  hide-details
                                  class="ml-6"
                                ></v-checkbox>
                              </div>
                              <v-subheader class="services-subheader mt-6"
                                >Documennts Details</v-subheader
                              >
                              <div class="d-flex mt-n2 mt-4">
                                <v-checkbox
                                  label="Document"
                                  v-model="services"
                                  disabled
                                  hide-details
                                ></v-checkbox>
                                <v-checkbox
                                  label="Document proof"
                                  v-model="services"
                                  disabled
                                  hide-details
                                  class="ml-6"
                                ></v-checkbox>
                                <v-checkbox
                                  label="Document visibility "
                                  v-model="services"
                                  disabled
                                  hide-details
                                  class="ml-6"
                                ></v-checkbox>
                              </div>
                              <div class="d-flex">
                                <v-checkbox
                                  label="Document Country"
                                  v-model="services"
                                  disabled
                                  hide-details
                                ></v-checkbox>

                                <v-checkbox
                                  label="Document Type"
                                  v-model="services"
                                  disabled
                                  hide-details
                                  class="ml-6"
                                ></v-checkbox>
                              </div>
                              <div class="d-flex">
                                <v-checkbox
                                  label="Documents Must not be Expired"
                                  v-model="services"
                                  disabled
                                  hide-details
                                ></v-checkbox>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-10 mb-4"></v-divider>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-subheader class="subtitle-2 pa-0"
                            >Face</v-subheader
                          >

                          <div class="d-flex">
                            <v-sheet
                              color="white"
                              elevation="2"
                              height="150px"
                              outlined
                              rounded
                              width="200"
                              class="ml-0"
                              ><img
                                height="160"
                                :src="`${verificationDetail.Face_In_Real}`"
                                width="100%"
                              />
                            </v-sheet>

                            <v-sheet
                              color="white"
                              elevation="2"
                              height="160px"
                              outlined
                              rounded
                              width="200"
                              class="ml-12"
                              ><img
                                height="100%"
                                :src="`${verificationDetail.Face_In_Card}`"
                                width="100%"
                            /></v-sheet>
                          </div>
                          <v-subheader class="subtitle-2 pa-0"
                            >Extracted Images</v-subheader
                          >

                          <div class="d-flex">
                            <v-sheet
                              color="white"
                              elevation="2"
                              height="50"
                              outlined
                              rounded
                              width="200"
                              class="ml-0"
                            >
                              <img
                                height="100%"
                                :src="`${verificationDetail.Signature_Image}`"
                                width="100%"
                            /></v-sheet>
                            <v-sheet
                              color="white"
                              elevation="2"
                              height="150"
                              outlined
                              rounded
                              width="200"
                              class="ml-12"
                              ><img
                                height="100%"
                                :src="`${verificationDetail.Card_Image}`"
                                width="100%"
                            /></v-sheet>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-subheader class="section-header"
                            >Additional Data</v-subheader
                          >
                          <table class="General-Location">
                            <tr>
                              <td class="header">Full Name</td>
                              <td class="subheader">
                                {{ verificationDetail.Person_Name }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Father Name:</td>
                              <td class="subheader">
                                {{ verificationDetail.Father_Name }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Issue Date</td>
                              <td class="subheader">
                                {{ verificationDetail.Card_Reg_Date }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Expiry Date</td>
                              <td class="subheader">
                                {{ verificationDetail.Card_Exp_Date }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Date of Birth:</td>
                              <td class="subheader">
                                {{ verificationDetail.Date_of_Birth }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Gender</td>
                              <td class="subheader">
                                {{ verificationDetail.Gender }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Country</td>
                              <td class="subheader">
                                {{
                                  verificationDetail.verified_data
                                    ? verificationDetail.verified_data.country
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">Document Type:</td>
                              <td class="subheader">
                                {{ verificationDetail.Card_Type }}
                              </td>
                            </tr>
                            <tr>
                              <td class="header">ID Card Number</td>
                              <td class="subheader">
                                {{ verificationDetail.ID_Card_No }}
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-10 mb-4"></v-divider>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          class="mt-0 mb-0 pt-0 pb-0"
                        >
                          <v-subheader class="section-header"
                            >Browser Info</v-subheader
                          >
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                          <v-list-item two-line>
                            <v-list-item-avatar size="40">
                              <v-avatar size="40" color="primary">
                                <v-icon color="white">mdi-globe-model</v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>Browser</v-list-item-title>
                              <v-list-item-subtitle>{{
                                verificationDetail.systeminfo.browser
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                          <v-list-item two-line>
                            <v-list-item-avatar size="40">
                              <v-avatar size="40" color="primary">
                                <v-icon color="white">mdi-orbit</v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>Platform</v-list-item-title>
                              <v-list-item-subtitle>{{
                                verificationDetail.systeminfo.os
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                          <v-list-item two-line>
                            <v-list-item-avatar size="40">
                              <v-avatar size="40" color="primary">
                                <v-icon color="white">mdi-desktop-mac</v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>Device Name</v-list-item-title>
                              <v-list-item-subtitle>{{
                                verificationDetail.systeminfo.brand
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="3" xl="3">
                          <v-list-item two-line>
                            <v-list-item-avatar size="40">
                              <v-avatar size="40" color="primary">
                                <v-icon color="white">mdi-devices</v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>Device Type</v-list-item-title>
                              <v-list-item-subtitle>{{
                                verificationDetail.systeminfo.deviceType
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["verification"],
  data() {
    return {
      showOverlay: true,
      verificationDetail: null,
      dialog: true,
      services: true,
    };
  },
  created() {
    // console.log(this.verification.verificationid)
    this.showOverlay = true;
    this.singleVerificationDetail(this.verification.verificationid)
      .then((res) => {
        this.showOverlay = false;
        this.verificationDetail = res.data;
        // console.log("res", res);
      })
      .catch((e) => {
        console.log("error", e);
      });
  },
  methods: {
    ...mapActions("deo", ["singleVerificationDetail"]),
    changeVerification() {
      this.$router.push({
        name: "deoDetails",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  font-weight: 700;
  font-size: 18px;
  padding: 0px;
}
.General-Location {
}
.General-Location .header {
  font-weight: 600;
  width: 130px;
}
.General-Location .subheader {
  font-weight: 500;
}
.services-subheader {
  padding: 0px;
  font-weight: 500;
  height: 10px;
}
.Browser-Info {
  width: 100%;
}
</style>