<template>
  <div>
    <v-row>
        <v-col cols="6">
            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Business   Name: {{invoiceData[0].businessname}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Legal Name: {{invoiceData[0].legalname}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Status: {{invoiceData[0].status}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Packageid: {{invoiceData[0].packageid}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Eexpiry Date: {{invoiceData[0].expirydate}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>


            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Business Owner: {{invoiceData[0].business_owner}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>


            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Business Contact: {{invoiceData[0].business_contact}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Business Email: {{invoiceData[0].business_email}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Business Fax: {{invoiceData[0].business_fax}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Organization Type: {{invoiceData[0].organization_type}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Ma Country: {{invoiceData[0].ma_country}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Ma City: {{invoiceData[0].ma_city}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Ma Province: {{invoiceData[0].ma_province}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>



    
        </v-col>
        <v-col cols="6">
                        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Ma Zip: {{invoiceData[0].ma_zip}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Ma streetline1: {{invoiceData[0].ma_streetline1}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Ma Streetline2: {{invoiceData[0].ma_streetline2}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Office Phoneno: {{invoiceData[0].office_phoneno}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Cell Phoneno: {{invoiceData[0].cell_phoneno}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Personal Phoneno: {{invoiceData[0].personal_phoneno}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Personal Cellno: {{invoiceData[0].personal_cellno}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Organization Details: {{invoiceData[0].organization_details}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>


            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Timezone: {{invoiceData[0].timezone}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>


            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Isbusinessdetails: {{invoiceData[0].isbusinessdetails}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Termsandconditions: {{invoiceData[0].termsandconditions}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Istermsandconditions: {{invoiceData[0].istermsandconditions}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

   

        </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions,mapGetters,mapMutations} from 'vuex'
export default {
    data(){
        return{
            invoiceData:[],
        }
    },
    created(){
        this.viewBussiness().then((res)=>{
            console.log("viewBussiness",res)
            this.invoiceData = res.data.data
        }).catch((err)=>{
            console.log("viewBussiness",err)
        })
    },
    computed:{
        ...mapGetters("business", ["getBranchesId"])
    },
    methods:{
        ...mapActions("business",["viewBussiness"]),
        
    }
}
</script>

<style>

</style>