<template>
  <div>
      <div class="d-flex">
        <h1>Active Branches</h1>
        <v-spacer></v-spacer>
      <v-btn class="mt-2" small rounded @click="inActiveBranches">show InActive </v-btn>
      </div>
      
      <v-data-table
    :headers="headers"
    :items="branches"
    :items-per-page="10"
    class="elevation-1"
  >
  <template v-slot:item.action="{ item }">
      
        <v-btn icon @click="viewInvoice(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn color="error" icon @click="deActivateBranches(item)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import {mapActions,mapGetters,mapMutations} from 'vuex'
export default {
    data(){
        return{
            branches:[],
            headers: [
          {
            text: 'Business Name',
            align: 'start',
            sortable: false,
            value: 'businessname',
          },
          {
            text: 'Branches Name',
            align: 'start',
            sortable: false,
            value: 'branchname',
          },
          {text:'Action',value:'action'}
        ],
        }
    },
    created(){
        this.getActiveBranches();
    },
    computed:{
        ...mapGetters("business",["getBusinessId"])
    },
    methods:{
        ...mapActions("branches",["activBranches","deactivateBranches"]),
        getActiveBranches(){
            this.activBranches(this.getBusinessId).then((res)=>{
                console.log("All activeBranches",res.data.data)
                this.branches=res.data.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        viewInvoice(item){
          let invoice = item
          this.$emit("wantToShowDialog",true)
          console.log("invoice deatails",invoice)
          this.$store.commit('branches/setBranchesId',invoice.branchid)
          // this.$router.push({
          //   name: "Business",
          //   params: { showComponent: "businesInvoice" },
          // });
        },
        deActivateBranches(item){
            console.log("fvvjdfhvdjfvbhjdfb",item)
            let branchID = item.branchid
            this.deactivateBranches(branchID).then((res)=>{
                console.log("deActivateBusiness result",res)
                this.getActiveBranches();
            }).catch((err)=>{
                console.log("error deActivateBusiness",err)
            })
        },
        inActiveBranches(){
          this.$router.push({
            name: "Branches",
            params: { showComponent: "inActiveBranches" },
          });
        }
    }
}
</script>

<style>

</style>