<template>
  <div>
      <div class="d-flex ">
      <h1>Active User</h1>
      <v-spacer></v-spacer>
      <v-btn class="mt-2" small rounded @click="inActiveUsers">show InActive </v-btn>
      </div>
      
      <v-data-table
    :headers="headers"
    :items="user"
    :items-per-page="10"
    class="elevation-1"
  >
  <template v-slot:item.action="{ item }">
      
        <v-btn icon @click="viewInvoice(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn color="error" icon @click="deActivateUser(item)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import {mapActions,mapGetters,mapMutations} from 'vuex'
export default {
    data(){
        return{
            user:[],
            headers: [
          {
            text: 'Business Name',
            align: 'start',
            sortable: false,
            value: 'businessname',
          },
          {
            text: 'User Name',
            align: 'start',
            sortable: false,
            value: 'username',
          },
          {
            text: 'User Email',
            align: 'start',
            sortable: false,
            value: 'useremail',
          },
          {
            text: 'User Contact',
            align: 'start',
            sortable: false,
            value: 'usercontact',
          },
          {
            text: 'Creation Date',
            align: 'start',
            sortable: false,
            value: 'creation_date',
          },
          {
            text: 'User Status',
            align: 'start',
            sortable: false,
            value: 'status',
          },
        //   {text:'Action',value:'action'}
        ],
        }
    },
    created(){
        this.getActiveUser();
    },
    computed:{
        ...mapGetters("business",["getBusinessId"])
    },
    methods:{
        ...mapActions("user",["activUser","deactivateUser"]),
        getActiveUser(){
            this.activUser(this.getBusinessId).then((res)=>{
                console.log("All active User",res.data.data)
                this.user=res.data.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        viewInvoice(item){
          let invoice = item
          this.$emit("wantToShowDialog",true)
          console.log("invoice deatails",invoice)
          this.$store.commit('user/setUserId',invoice.buserid)
          // this.$router.push({
          //   name: "Business",
          //   params: { showComponent: "businesInvoice" },
          // });
        },
        deActivateUser(item){
            console.log("fvvjdfhvdjfvbhjdfb",item)
            let buserID = item.buserid
            this.deactivateUser(buserID).then((res)=>{
                console.log("deActivateUser result",res)
                this.getActiveUser();
            }).catch((err)=>{
                console.log("error deActivateUser",err)
            })
        },
        inActiveUsers(){
          this.$router.push({
            name: "User",
            params: { showComponent: "inactive_user" },
          });
        }
    }
}
</script>

<style>

</style>